import { IPermissionSettingsResponse } from '@/interfaces/settings.interface';
import { $user } from './user.service';
import ObjectHelper from '@/utilities/object.helper';
import { SystemRoleEnum } from '@/interfaces/web-api/user-role.interface';

interface IPermissions {
    [key: string]: boolean | any | string | string[];
    header: any;
    grid: any;
    data: any;
}

class PermissionService {
    readonly defaultPermissions: IPermissions = {
        header: {
            info: true,
            settings: { 
                users: ['AdminRole'],
                import: true,
                integration: true,
                integration_monitor: false,
                kpi: ['AdminRole'],
                cause_codes: true,
                printers: ['AdminRole'],
                downloads: true,
                user_settings: ['GlobalAdminUserRole'],
                stock_attributes: ['AdminRole'],
                active_users: ['AdminRole'],
                can_edit_company_info: ['AdminRole']
            }
        },
        grid: {
            filters: true,
            custom_views: true,
            exports: true,
            create: true,
            grid_options: true,
            can_save_for_all: ['AdminRole'],
            can_save_for_bum: ['AdminRole'],
            can_save_for_me: true        
        },
        data: {
            navigation: true,
            complete_order: true,
            approve_inventory: true,
            approve_line: true,
            cancel_line: true,
            recount_inventory: true,
            view_history: true,
            assign_jobs: true,
            unassign_jobs: true,
            end_job: true,
            delete_locations: true,
            add_location: true,
            update_location: true,
            assign_to_user: true,
            assign_to_user_role: true,
            cancel_order: true,
            cancel_task: true,
            gain_stock: true,
            move_stock: true,
            lose_stock: true,
            create_inventory: true,
            view_reservations: true,
            adjust_picked_quantity: false,
            edit_order_quantity: true,
            assign_jobs_to_user: true,
            prepare: true,
            partly_prepare: false,
            unprepare: true,
            cancel_order_process: false,
            change_priority_date: true,
            edit_min_max: true,
            dedicate_location_to_material: false,
            remove_dedicated_location: false,
            add_supplier: true,
            update_supplier: true,
            delete_supplier: true,
            remove_supplier_product: true,
            add_product_to_supplier: true,
            save_draft: true,
            update_draft: true,
            show_search_criteria_for_draft: true,
            can_edit_company_info: false,
            create_sales_order: true,
            edit_sales_order_draft: true,
            delete_sales_order_draft: true,
        }
    };

    private permissions = this.defaultPermissions;

    load(permissions?: IPermissionSettingsResponse) {
        if (!permissions) { return; }

        const newPermissions = this.parseSettings(permissions);
        const defaultPermissions = ObjectHelper.deepClone(this.defaultPermissions);
        
        this.permissions = ObjectHelper.deepAssign(defaultPermissions, newPermissions);
    }

    hasFor(...nodes: string[]): boolean {
        return this.hasPermission(nodes);
    }

    private hasPermission(nodes: string[]): boolean {
        if (!nodes || !nodes.length) { return $user.isAdmin; }

        let hasPermission = false;
        let object: boolean | string | string[] | any = this.permissions;

        nodes.forEach(node => {
            object = Object.prototype.hasOwnProperty.call(object, node) ? object[node] : false;

            if (typeof (object) === 'boolean') {
                // note: handle forced permission
                hasPermission = object;
            } else if (Array.isArray(object)) {
                // note: handle string array with allowed userRoleNames
                hasPermission = object.some(userRole => this.hasUserRole(userRole));
            } else if (typeof (object) === 'string') {
                // note: handle single userRoleName
                hasPermission = this.hasUserRole(object);
            }
        });

        return hasPermission;
    }

    private hasUserRole(name: string): boolean {
        const { roles } = $user;

        return roles.some(role => role.userRoleName === name || role.systemRole === SystemRoleEnum.GlobalAdminUser);
    }

    private parseSettings(settings: IPermissionSettingsResponse): Partial<IPermissions> {
        const permissionsObj: Partial<IPermissions> = {};
        const { header, grid, data } = settings;
        
        try {
            permissionsObj.header = header ? JSON.parse(header) : {};
        } catch (error) {
            console.error('Permissions: header settings cannot be parse', error, header);
        }
        try {
            permissionsObj.grid = grid ? JSON.parse(grid) : {};
        } catch (error) {
            console.error('Permissions: grid settings cannot be parse', error, grid);
        }
        try {
            permissionsObj.data = data ? JSON.parse(data) : {};
        } catch (error) {
            console.error('Permissions: data settings cannot be parse', error, data);
        }

        return permissionsObj;
    }
}

export const $permission = new PermissionService();
