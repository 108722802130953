

import { ICustomModalConfig } from '@/interfaces/custom-modal.interface';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

interface IState {
    top: number;
    left: number;
    zIndex: number;
    className: string;
    isFocused: boolean;
    eventHandlers: any;
}

@Component({})
export default class BaseModal<T extends ICustomModalConfig = ICustomModalConfig> extends Vue {
    @Prop({ type: Object, required: true }) config!: T;

    @Watch('state.isFocused') onFocus(isFocused: boolean) {
        if (isFocused) {
            this.ModalState.zIndex = this.$modal.nextIndex();
        } else {
            this.ModalState.className = '';
        }
    }
    
    mounted() {
        this.getPosition();
    }
    
    ModalState: IState = {
        top: -1,
        left: -1,
        zIndex: 1000,
        isFocused: false,
        className: 'custom-modal--init',
        eventHandlers: {
            mousemove: { active: false, handler: this.onMouseMove.bind(this) },
            mouseup: { active: false, handler: this.onMouseUp.bind(this) }
        },
    };
    
    get Style() {
        let style = '';
        // todo: if n-th modal is opening it should be translated on X and Y as well as on Z
        if (this.ModalState.className !== 'custom-modal--init') {
            style = `z-index: ${this.ModalState.zIndex};top: ${this.ModalState.top}px; left: ${this.ModalState.left}px;`;
        } else {
            style = `z-index: ${this.ModalState.zIndex};`;
        }

        return style;
    }

    close() {
        this.$modal.close(this.config.id || 0);
    }
    
    beforeDestroy() {
        this.dispose();
    }
    
    onMouseDown(event: MouseEvent) {
        const { target } = event;
        const excludedTags = ['INPUT', 'TEXTAREA', 'SELECT'];
        if (target && excludedTags.includes((target as HTMLElement).tagName)) {
            // note: do not prevent default behavior if input was selected
            return;
        }
        this.init(event);       
        event.preventDefault()
    }
    
    onMouseMove(event: MouseEvent) {
        const { movementX, movementY } = event;

        this.ModalState.left += movementX;
        this.ModalState.top += movementY;
    }
    
    onMouseUp(event: MouseEvent) {
        this.dispose();
    }
    
    private getPosition() {
        this.ModalState.zIndex = this.$modal.nextIndex();
    }
    
    private init(event: MouseEvent) {
        this.ModalState.isFocused = true;
        
        if (this.isNodeClick(event, 'HEADER') === false) {
            // prevent if clicked anything except header
            return;
        }
        
        if (this.ModalState.className === 'custom-modal--init') {
            const cords = this.getCords(event);
            this.ModalState.left = cords.x;
            this.ModalState.top = cords.y;
        }
        
        this.ModalState.className = 'custom-modal--active';
        
        this.addEventListeners();
    } 
    
    private dispose() {
        this.ModalState.isFocused = false;
        
        this.removeEventListeners();
    }
    
    private addEventListeners() {
        for (const key in this.ModalState.eventHandlers) {
            this.addEventListener(key);
        }
    }
    
    private addEventListener(eventName: string) {
        if (this.ModalState.eventHandlers.hasOwnProperty(eventName) && this.ModalState.eventHandlers[eventName].active === false) {
            const { handler } = this.ModalState.eventHandlers[eventName];
            document.addEventListener(eventName, handler);
            this.ModalState.eventHandlers[eventName].active = true;
        }
    }
    
    private removeEventListeners() {
        for (const key in this.ModalState.eventHandlers) {
            this.removeEventListener(key);
        }
    }
    
    private removeEventListener(eventName: string) {
        if (this.ModalState.eventHandlers.hasOwnProperty(eventName) && this.ModalState.eventHandlers[eventName].active === true) {
            const { handler } = this.ModalState.eventHandlers[eventName];
            document.removeEventListener(eventName, handler);
            this.ModalState.eventHandlers[eventName].active = false;
        }
    }
    
    private getCords(event: MouseEvent) {
        const { clientX, clientY, offsetX, offsetY, target } = event;   

        const x = clientX - offsetX - 16;
        const y = clientY - offsetY - 1;

        return {
            x,
            y
        };
    }
    
    private isNodeClick(event: any, nodeName: string) {
        let { target } = event;
        
        while (target.parentNode) {
            if (target.nodeName === nodeName) {
                return true;
            } else if (target.classList.contains('custom-modal')) {
                return false;
            }
            
            target = target.parentNode;
        }
        
        return false;
    }
}

