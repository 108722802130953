import { IGridSettings } from '@/interfaces/settings.interface';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import { FilterColumnModuleState, FilterColumnState } from '../states/filter-column-setting-interface';
import StringHelper from '@/utilities/string.helper';

@Module({ dynamic: true, store, name: 'filterColumnSettings' })
class filterColumnSettingModule extends VuexModule implements FilterColumnModuleState {
    [key: string]: any;
    // BaseDataGridPage
    Stock:null | FilterColumnState = null;
    Task:null | FilterColumnState = null;
    // BaseMasterDetailPage
    IngoingOrder:null | FilterColumnState = null;
    IntegrationImports:null | FilterColumnState = null;
    IntegrationMessages:null | FilterColumnState = null;
    IntegrationRequests:null | FilterColumnState = null;
    IntegrationMonitor:null | FilterColumnState = null;
    Inventory:null | FilterColumnState = null;
    Job:null | FilterColumnState = null;
    Supplier:null | FilterColumnState = null;
    Draft:null | FilterColumnState = null;
    Location:null | FilterColumnState= null;
    Maintenance:null | FilterColumnState = null;
    Material:null | FilterColumnState= null;
    Operation:null | FilterColumnState= null;
    OutgoingOrder:null | FilterColumnState= null;
    SalesOrderDraft:null | FilterColumnState= null;

    @Mutation
    setJobColumnState(dxState: FilterColumnState): void {
        this.Job = dxState;
    }

    @Mutation
    setLocationColumnState(dxState: FilterColumnState): void {
        this.Location = dxState;
    }

    @Mutation
    setMaintenanceColumnState(dxState: FilterColumnState): void {
        this.Maintenance = dxState;
    }
    @Mutation
    setDraftColumnState(dxState: FilterColumnState): void {
        this.Draft = dxState;
    }

    @Mutation
    setInventoryColumnState(dxState: FilterColumnState): void {
        this.Inventory = dxState;
    }
    @Mutation
    setSupplierColumnState(dxState: FilterColumnState): void {
        this.Supplier = dxState;
    }

    @Mutation
    setMaterialColumnState(dxState: FilterColumnState): void {
        this.Material = dxState;
    }

    @Mutation
    setOperationColumnState(dxState: FilterColumnState): void {
        this.Operation = dxState;
    }

    @Mutation
    setIngoingOrderColumnState(dxState: FilterColumnState): void {
        this.IngoingOrder = dxState;
    }

    @Mutation
    setOutgoingOrderColumnState(dxState: FilterColumnState): void {
        this.OutgoingOrder = dxState;
    }

    @Mutation
    setStockColumnState(dxState: FilterColumnState): void {
        this.Stock = dxState;
    }

    @Mutation
    setTaskColumnState(dxState: FilterColumnState): void {
        this.Task = dxState;
    }

    @Mutation
    setIntegrationImportsColumnState(dxState: FilterColumnState): void {
        this.IntegrationImports = dxState;
    }

    @Mutation
    setIntegrationMessagesColumnState(dxState: FilterColumnState): void {
        this.IntegrationMessages = dxState;
    }

    @Mutation
    setIntegrationRequestsColumnState(dxState: FilterColumnState): void {
        this.IntegrationRequests = dxState;
    }

    @Mutation
    setIntegrationMonitorColumnState(dxState: FilterColumnState): void {
        this.IntegrationMonitor = dxState;
    }

    @Mutation
    setSalesOrderDraftColumnState(dxState: FilterColumnState): void {
        this.SalesOrderDraft = dxState;
    }


    @Mutation
    dispose(): void {
        this.Stock = null;
        this.Task = null;
        this.IngoingOrder = null;
        this.IntegrationImports = null;
        this.IntegrationMessages = null;
        this.IntegrationRequests = null;
        this.IntegrationMonitor = null;
        this.Inventory = null;
        this.Job = null;
        this.Location = null;
        this.Maintenance = null;
        this.Material = null;
        this.Operation = null;
        this.OutgoingOrder = null;
        this.SalesOrderDraft = null;
    }

    get getJobesFilterColumn(): any {
        return this.Job;
    }
    get getStockesFilterColumn(): any {
        return this.Stock;
    }
    get getLocationesFilterColumn(): any {
        return this.Location;
    }
    get getTaskesFilterColumn(): any {
        return this.Task;
    }
    get getDraftesFilterColumn(): any {
        return this.Draft;
    }
    get getLocationFilterColumn(): any {
        return this.Location;
    }

    get getMaintenanceesFilterColumn(): any {
        return this.Maintenance;
    }

    get getInventoryesFilterColumn(): any {
        return this.Inventory;
    }

    get getMaterialFilterColumn(): any {
        return this.Material;
    }
    get getIngoingOrderesFilterColumn(): any {
        return this.IngoingOrder;
    }

    get getOutgoingOrderesFilterColumn(): any {
        return this.OutgoingOrder;
    }

    get getMaterialesFilterColumn(): any {
        return this.Material;
    }
    get getSupplieresFilterColumn(): any {
        return this.Supplier;
    }
    get getOperationesFilterColumn(): any {
        return this.Operation;
    }

    get getSalesOrderDraftesFilterColumn(): any {
        return this.SalesOrderDraft;
    }

    @Action
    async setFilterColumnSettings(settings: IGridSettings) {
        if (!settings) { return; }
        for (const key in settings) {
            if (settings.hasOwnProperty(key)) {
                const stateStr = settings[key];

                if (stateStr) {

                    try {
                        const state = JSON.parse(stateStr);
                        const getterKey = StringHelper.startWithUpperCase(key);
                        const setterKey = `set${getterKey}ColumnState`;
                        if (this.hasOwnProperty(setterKey)) {
                            this[setterKey](state);
                        }
                    } catch (error) {}
                }
            }
        }
    }
}

export const filterColumnSettings = getModule(filterColumnSettingModule);
