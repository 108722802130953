import { ISignalRSettings } from '@/interfaces/web-api/signalR.interface';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import { ISignalRState } from '../states/signalR-state.interface';

@Module({ dynamic: true, store, name: 'signalR' })
class SignalRModule extends VuexModule implements ISignalRState {
    isAzureSignalR: boolean = false;
    signalREndpoint: string = '';
    signalRToken: string = '';

    get getSignalRSettings(): ISignalRSettings {
        const settings: ISignalRSettings = {
            isAzureSignalR: this.isAzureSignalR,
            signalREndpoint: this.signalREndpoint,
            token: this.signalRToken
        };

        return settings;
    }

    @Mutation
    setIsAzureSignalR(isAzureSignalR: boolean): void {
        this.isAzureSignalR = isAzureSignalR;
    }

    @Mutation
    setSignalREndpoint(signalREndpoint: string): void {
        this.signalREndpoint = signalREndpoint;
    }

    @Mutation
    setSignalRToken(token: string): void {
        this.signalRToken = token;
    }

    @Action
    setSignalRSettings(settings: ISignalRSettings): void {
        const { isAzureSignalR, signalREndpoint, token } = settings;

        this.setIsAzureSignalR(isAzureSignalR);
        this.setSignalREndpoint(signalREndpoint);
        this.setSignalRToken(token);
    }

    @Action
    dispose(): void {
        this.setIsAzureSignalR(false);
        this.setSignalREndpoint('');
        this.setSignalRToken('');
    }
}

export const signalRModule = getModule(SignalRModule);
