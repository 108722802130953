import moment from 'moment';

let timezoneOffsetHours: number;

function convertDateToMoment(date: Date): moment.Moment {
    return moment(date);
}

function convertStringToMoment(dateStr: string): moment.Moment {
    return moment(dateStr);
}

function addTimezoneOffset(dateUTC: moment.Moment): moment.Moment {
    if (!timezoneOffsetHours) {
        timezoneOffsetHours = dateUTC.utcOffset() / 60;
    }    
    
    return dateUTC.set({
        hour: dateUTC.get('hour') + timezoneOffsetHours
    });
}

function formatDate(date: moment.Moment, format: string): string {
    return date.format(format);
}

export function formatDateTimeToString(
    date: Date | string | moment.Moment, 
    format?: string, 
    timezone: boolean = true
    ): string {
        
    if (!date) {
        return '';
    }

    if (date instanceof Date) {
        date = convertDateToMoment(date);
    } else if (typeof date === 'string') {
        date = convertStringToMoment(date);
    }
    
    if (timezone) {
        date = addTimezoneOffset(date as moment.Moment);
    }
    if (format) {
        return formatDate(date as moment.Moment, format);
    }
    
    return date.toString();
}
