import { formatDateTimeToString } from './fromat-date-time.function';

const REGEX = {
    DATE: /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/,
    DATETIME: /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])(T|\s)(2[0-3]|[01][0-9]):[0-5][0-9]/
};

const FORMAT = {
    DATE: 'YYYY-MM-DD',
    DATETIME: 'YYYY-MM-DD HH:mm:ss',
    TIME: 'HH:mm:ss'
};

/**
 * 
 * @param format if null use default formatting based on received date
 * @param data to be formated
 */
export function formatDates(format: string, ...data: any[]): any[] {
    const formatted = data.map(entry => {
        for (const key in entry) {
            let dateStr = null;
            const value = entry[key];
            
            if (Array.isArray(value) && value.length > 0) {
                entry[key] = formatDates(format, ...value);
            } else {
                dateStr = formatBasedOnType(value, format);
            }
            
            if (dateStr && entry[key] !== dateStr) {
                entry[key] = dateStr;
            }  
        }
        
        return entry;
    });
    
    return formatted;
}

function formatBasedOnType(value: string, format: string): string {
    if (isDateTimeFormat(value)) {
        return formatDateTimeToString(value, format || FORMAT.DATETIME);                   
    } else if (isDateFormat(value)) {
        return formatDateTimeToString(value, format || FORMAT.DATE);         
    }
    
    return value;
}

function isDateFormat(value: string): boolean {
    const pattern = REGEX.DATE;
    return pattern.test(value);
}

function isDateTimeFormat(value: string): boolean {
    const pattern = REGEX.DATETIME;
    return pattern.test(value);
}
