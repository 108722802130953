export interface IStaticTranslations {
    [key: string]: any;
}

export const staticTranslations: IStaticTranslations = {
    'da': getFilesFor('da-DK'),
    'da-DK': getFilesFor('da-DK'),
    'de': getFilesFor('de'),
    'en': getFilesFor('en-GB'),
    'en-GB': getFilesFor('en-GB'),
    'nb-NO': getFilesFor('nb-NO'),
};

export const dxTranslations: IStaticTranslations = {
    'da': getDxFilesFor('da-DK'),
    'da-DK': getDxFilesFor('da-DK'),
    'de': getDxFilesFor('de'),
    'en': getDxFilesFor('en-GB'),
    'en-GB': getDxFilesFor('en-GB'),
    'nb-NO': getDxFilesFor('nb-NO'),
};

function getFilesFor(langCode: string) {
    let translations = {};
    let sTranslations = {};

    try {
        sTranslations = require(`./${langCode}/static-translations.json`);
    } catch (error) {}

    try {
        translations = require(`./${langCode}/translations.json`);

        translations = {...translations, ...sTranslations};
    } catch (error) {}

    return translations;
}


function getDxFilesFor(langCode: string) {
    if (!langCode || langCode.length < 2) {
        return {};
    }

    let translations = {};
    const shortLangCode = langCode.substring(0, 2);

    try {
        translations = require(`./${langCode}/dx-translations.${shortLangCode}.json`);
    } catch (error) {}

    return translations;
}
