export enum ErrorCode {
    FR_TOO_MANY_REDIRECTS = 'ERR_FR_TOO_MANY_REDIRECTS',
    BAD_OPTION_VALUE = 'ERR_BAD_OPTION_VALUE',
    BAD_OPTION = 'ERR_BAD_OPTION',
    NETWORK = 'ERR_NETWORK',
    DEPRECATED = 'ERR_DEPRECATED',
    BAD_RESPONSE = 'ERR_BAD_RESPONSE',
    BAD_REQUEST = 'ERR_BAD_REQUEST',
    NOT_SUPPORT = 'ERR_NOT_SUPPORT',
    INVALID_URL = 'ERR_INVALID_URL',
    CANCELED = 'ERR_CANCELED',
    ECONNABORTED = 'ECONNABORTED',
    ETIMEDOUT = 'ETIMEDOUT',
}
