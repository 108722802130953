import { $environment } from "@/services/environment.service";

export default class UrlHelper {

    // you can see the valid and invalid examples for the regex here:
    // https://regex101.com/r/HUNasA/2
    static readonly urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    static isUrl(text: string): boolean {  
        return this.urlRegex.test(text);
    }

    static buildAnchor(url: string): string {  
        return `<a href="${url}" target="_blank">${url}</a>`;
    }

    static create(url: string, baseUrl?: string) {
        return new UrlBuilder(url, baseUrl || $environment.apiURL)
    }
}

interface IParam {
    key: string;
    value: any;
}
class UrlBuilder {
    readonly params: IParam[] = [];
    constructor(public endpoint: string, public baseUrl: string) {}

    addParam(key: string, value: any): this {
        this.params.push({ key, value })

        return this;
    }
    setParam(key: string, value: any): this {
        const idx = this.params.findIndex(i => i.key === key);
        if (idx === -1) {
            return this.addParam(key, value);
        } 
        
        this.params[idx].value = value;
        return this;
    }

    toUrl(): string {
        const query = this.toQuery();

        if (query) {
            return `${this.endpoint}?${query}`;
        }

        return this.endpoint;
    }

    toFullUrl(): string {
        return `${this.baseUrl}/${this.toUrl()}`;
    }

    toQuery(): string {
        try {
            const params: string[] = [];
            for (const { key, value } of this.params) {
                params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            }
            return params.join('&');
        } catch (error) {
            throw new Error('UrlBuilder: toQuery error!')
        }
    }
}