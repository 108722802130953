import { signalRService } from './signalR.service';
import { $http } from './http.service';
import DxDataService from './dx-data.service';
import { formatDates } from '@/utilities/format-dates.function';
import { IDxFilter } from '@/interfaces/dx-filter.interface';
import { filterService } from '@/services/dx-filter.service';
import { IApportResponse } from '@/interfaces/api.interface';
import { IDxGrid } from '@/interfaces/dx-grid.interface';

//#region INTERFACES
interface IHubMessage {
    methodName: HubMethodName;
    viewName: string;
}

interface IHubRegularMessage extends IHubMessage {
    id: string;
    viewModel: IHubView;
}

interface IHubBulkMessages extends IHubMessage {
    viewModels: any;
}

interface IHubBulkDeleteMessages extends IHubMessage {
    ids: any[];
}

interface IHubView {
    id: string;
    deleted: boolean;
}

type HubMethodName = 'Create' | 'Update' | 'Deleted' | 'Bulk' | 'BulkDelete';
//#endregion

class DataService {
    private instances: Map<string, any> = new Map();
    private filterFunc?: (signalRViewName: string) => Promise<IDxFilter[]>;
    
    initSignalR(signalRViewName: string, signalRViewTypes: string[], instance: any, filterFunc?: (signalRViewName: string) => Promise<IDxFilter[]>) {
        this.filterFunc = filterFunc;

        const dispatcher = this.getDispatcher(signalRViewName, instance);

        signalRService.init(dispatcher, 'updated', signalRViewName, signalRViewTypes);
    }

    isActiveSubscription(signalRViewName: string): boolean {
        return signalRService.isActiveSubscription(signalRViewName);
    }

    disableSignalR(signalRViewName: string) {
        if (!this.instances.has(signalRViewName)) {
            return;
        }

        this.instances.delete(signalRViewName);
        signalRService.delete(signalRViewName);
    }

    async callApi<T>(url: string, showLoader: boolean = false): Promise<T> {
        try {
            const data = await $http.get<T>(url, null, showLoader);
        
            // note: fix for BE not sending all responses as IApportResponse
            return data.result || ((data as any) as T);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    async callServerSideApi<T>(url: string, payload:any, showLoader:boolean): Promise<IApportResponse<T>> {
        try {
            const data = await $http.post<T>(url, payload, showLoader);
            // note: fix for BE not sending all responses as IApportResponse
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }


    async getServerSideApiCall<T>(url: string, showLoader:boolean): Promise<IApportResponse<T>> {
        try {
            const data = await $http.get<T>(url,  showLoader);
            // note: fix for BE not sending all responses as IApportResponse
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
    
    async callApiAndUpdate<T>(url: string, instance: any, showLoader: boolean = false): Promise<void>  {
        try {
            this.lockInstance(instance);

            const data = await this.callApi<T>(url, showLoader);

            this.update<T>(data, instance);
            
            return this.handleSuccess(instance);
        } catch (error) {
            return this.handleError(error, instance);
        }
    }
    
    async callApiAndReplace<T>(url: string, instance: any, objectKey: string, showLoader: boolean = false): Promise<void>  {
        try {
            this.lockInstance(instance);

            const data = await this.callApi<T>(url, showLoader);
            
            this.replace<T>(data, instance, objectKey);
            
            return this.handleSuccess(instance);
        } catch (error) {
            return this.handleError(error, instance);
        }
    }

    update<T>(data: T, instance: any, isHistorical: boolean = true) {
        const dxDataService = new DxDataService(instance);
        dxDataService.changeData<T>(data, isHistorical);
    }
    
    replace<T>(data: T, instance: any, objectKey: string = 'id') {
        const dxDataService = new DxDataService(instance);
        
        dxDataService.replaceData<T>(data, objectKey);
    }
    
    removeAll<T>(data: T, instance: any) {
        const dxDataService = new DxDataService(instance);

        dxDataService.removeData(data);
    }
    
    removeOne(id: string, instance: any) {
        const dxDataService = new DxDataService(instance);

        dxDataService.removeData(null, id);
    }

    reloadDataGrid(dataSource: any) {
        const ds = dataSource?.getDataSource();
        if(ds) {
            ds.reload();
        }
    }

    private handleSuccess(instance: any) {
        this.unlockInstance(instance);

        return Promise.resolve();
    }

    private handleError(error: any, instance: any) {
        this.unlockInstance(instance);

        return Promise.reject(error);
    }

    private async dispatch(response: IHubRegularMessage | IHubBulkMessages | IHubBulkDeleteMessages) {
        const instance = this.instances.get(response.viewName);

        if (!instance || !response) {
            return;   
        }

        if (instance.callback) {
            const message = response as IHubRegularMessage;
            return instance.callback(message.viewModel);
        }
        
        const dxDataService = new DxDataService(instance);

        if (response.methodName === 'Deleted') {
            const message = response as IHubRegularMessage;
            dxDataService.removeData(message.viewModel, message.id);
        } else if (response.methodName === 'Bulk') {
            const message = response as IHubBulkMessages;
            const filters = this.filterFunc ? await this.filterFunc(message.viewName) : false;
            let isHistorical = false;

            if (filters && message.viewModels.length > 0) {
                const historicalFilter = filters.find(filter => filter.name === 'historical');
                isHistorical = historicalFilter ? historicalFilter.value : false;
            }

            let receivedData = message.viewModels
                .map((viewModel: any) => formatDates('', viewModel))
                .map((viewModel: any) => {
                    let receivedData = viewModel;
                    if (filters) {
                        receivedData = this.applyFilters(receivedData, filters);
                    }

                    return receivedData;
                });

            dxDataService.bulkChangeData<any[]>(receivedData, isHistorical);
        } else if (response.methodName === 'BulkDelete') {
            const message = response as IHubBulkDeleteMessages;
            dxDataService.bulkRemoveData(message.ids);
        } else {
            const message = response as IHubRegularMessage;
            let receivedData = formatDates('', message.viewModel);
            let isHistorical = false;

            if (this.filterFunc) {
                const filters = await this.filterFunc(message.viewName);

                if (filters) {
                    receivedData = this.applyFilters(receivedData, filters);
                    
                    const historicalFilter = filters.find(filter => filter.name === 'historical');
                    isHistorical = historicalFilter ? historicalFilter.value : false;
                }
            }
            
            const resetGrid = false;
            dxDataService.changeData<any[]>(receivedData, isHistorical, resetGrid);
        }
    }
    
    private getDispatcher(signalRViewModel: string, instance: any): any {
        if (!this.instances.has(signalRViewModel)) {
            this.saveInstance(instance, signalRViewModel);
        }
        
        return this.dispatch.bind(this);              
    }

    private saveInstance(instance: any, signalRViewModel: string) {
        /* tslint:disable:no-string-literal */
        instance['SIGNALR'] = signalRViewModel;
        /* tslint:enable:no-string-literal */

        this.instances.set(signalRViewModel, instance);
    }
    
    private applyFilters(data: any[], filters: IDxFilter[]) {
        const filteredData = data.filter(entry => {
            return filters.every(filter => {
                const filterName = filter.dxPropName || filter.queryName;
                
                if (entry && entry.hasOwnProperty(filterName)) {
                    return filterService.compare(entry, filter);
                }
                return true;
            });
        });
        
        return filteredData;
    }
    
    private lockInstance(instance: IDxGrid) {
        if(instance) {
            instance.LOCKED = true;
        }
    }

    private unlockInstance(instance: IDxGrid) {
        if(instance) {
            instance.LOCKED = undefined;
        }
    }

}

export const dataService = new DataService();
