







import { Component, Prop, Vue } from 'vue-property-decorator';
import { IKpi } from '@/interfaces/web-api/kpi.interfaces';

@Component({})
export default class DynamicKpi extends Vue {
    
    @Prop({ type: Object, required: true }) data!: IKpi;
    @Prop({ type: Boolean, required: false }) isDraggable!: boolean;
    
    component: any = null;

    async mounted() {
        try {
            await this.loader();

            this.component = () => this.loader();
        } catch (error) {
           this.handleError();
        }
    }
    
    private loader() {
        if (!this.template) {
            return null;
        }
        
        return import(`@/components/kpi/templates/${this.template}.vue`);       
    }
    
    private get template(): string {
        if (!this.data) { return ''; }
        
        switch (this.data.name) {
            case 'InBoundOrderReportData':
            case 'OutBoundOrderReportData':
                return 'OrderKpi';
            case 'PickingTaskPerLocationRoleReportData':
                return 'LocationKpi';
            default:
                return '';
        }
    }
    
    private handleError() {
        let msg = 'Template for dynamic kpi not found! ';
        if (!this.data) {
            msg += '(Missing KPI data)';
        } else if (!this.data.name) {
            msg += '(Missing KPI name)';
        } else {
            msg += `(Template not found for ${this.data.name} KPI)`;
        }
        
        this.$modal.showError(new Error(msg));
    }
}

