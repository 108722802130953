import { IKpi } from '@/interfaces/web-api/kpi.interfaces';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import { IKpiState } from '../states/kpi-state.interface';

@Module({ dynamic: true, store,  name: 'kpi' })
class KpiModule extends VuexModule implements IKpiState {
    kpis: IKpi[] = []; 
    
    get hasKpis() {
        return this.kpis.length > 0;
    }

    @Mutation
    setKpis(kpis: IKpi[]): void {
        this.kpis = kpis; 
    }
    
    @Action
    async dispose() {
        this.setKpis([]);
        return;
    }
}

export const kpiModule = getModule(KpiModule);
