import { render, staticRenderFns } from "./CustomModals.vue?vue&type=template&id=20e037ba&scoped=true"
import script from "./CustomModals.vue?vue&type=script&lang=ts"
export * from "./CustomModals.vue?vue&type=script&lang=ts"
import style0 from "./CustomModals.vue?vue&type=style&index=0&id=20e037ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e037ba",
  null
  
)

component.options.__file = "CustomModals.vue"
export default component.exports