import { ICustomModalConfig } from '@/interfaces/custom-modal.interface';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import { IModalState } from '../states/modal-state.interface';

@Module({ dynamic: true, store, name: 'modal' })
class ModalModule extends VuexModule implements IModalState {
    modals: ICustomModalConfig[] = [];
    zIndex: number = 1000;

    get getCount(): number {
        return this.modals.length;
    }
    
    @Mutation
    addModal(modal: ICustomModalConfig): void {
        this.modals.push(modal);
    }
    
    @Mutation
    removeModal(idx: number): void {
        this.modals.splice(idx, 1);
    }

    @Mutation
    incrementZIndex(): void {
        this.zIndex++;
    }
    
    @Mutation
    decrementZIndex(): void {
        if (this.zIndex > 1000) {
            this.zIndex--;
        }
    }

    @Mutation
    dispose(): void {
        this.modals = [];
        this.zIndex = 1000;
    }
    
    @Action
    closeModal(id: number): void {
        const idx = this.modals.findIndex(modal => modal.id === id);
        
        this.removeModal(idx);
        this.decrementZIndex();
    }

}

export const modalModule = getModule(ModalModule);
