interface IMasterDetailResizeData {
    master: number;
    detail: number;
    width: number;
}

export class MasterDetailResizeEvent extends CustomEvent<IMasterDetailResizeData> {
    static dispatch(size: IMasterDetailResizeData) {
        const event = new CustomEvent<IMasterDetailResizeData>('onmasterdetailcontentresize', { detail: size });

        window.dispatchEvent(event);
    }
    
    static register(handler: (event: Event) => void) {
        window.addEventListener('onmasterdetailcontentresize', handler);
    }
    static unregister(handler: (event: Event) => void) {
        window.removeEventListener('onmasterdetailcontentresize', handler);
    }
}

export class SignalREvent extends CustomEvent<any> {
    static dispatch(type: 'update', data: any) {
        const event = new CustomEvent<any>('onsignalrevent', { detail: {
            type,
            data
        } });

        window.dispatchEvent(event);
    }
    
    static register(handler: (event: Event) => void) {
        window.addEventListener('onsignalrevent', handler);
    }
    static unregister(handler: (event: Event) => void) {
        window.removeEventListener('onsignalrevent', handler);
    }
}

export class GridSearchEvent extends CustomEvent<void> {
    static dispatch() {
        const event = new CustomEvent<any>('onGridSearch');

        window.dispatchEvent(event);
    }
    
    static register(handler: (event: Event) => void) {
        window.addEventListener('onGridSearch', handler);
    }
    static unregister(handler: (event: Event) => void) {
        window.removeEventListener('onGridSearch', handler);
    }
}

export function isSignalREvent(obj: any): obj is ISignalREvent {
    return typeof obj === 'object' && isSignalREventDetail(obj.detail);
}

export function isSignalREventDetail(obj: any): obj is ISignalREventDetail {
    return typeof obj === 'object' && obj.hasOwnProperty('type') &&  obj.hasOwnProperty('data');
}

export function isSignalREventDetailData(obj: any): obj is ISignalREventDetailData {
    return typeof obj === 'object' && obj.hasOwnProperty('viewName') && typeof obj.viewModel === 'object';
}

interface ISignalREvent {
    detail: ISignalREventDetail;
}

interface ISignalREventDetail {
    type: string;
    data: ISignalREventDetailData;
}

interface ISignalREventDetailData {
    id: string;
    viewName: string;
    viewModel: any;
}
