import { LayoutWorkerService } from '@/workers/layout.worker';

class WebWorkersService {
    private _worker: any;
    
    get worker() {
        return this._worker;
    }
    
    get actions() {
        const actions = [
            { message: 'onResize', func: new LayoutWorkerService().onResize },
        ];
        
        return actions;
    }
    
    init(worker: any) {
        // 
        this._worker = worker;
    }
}

export const webWorkers = new WebWorkersService();
