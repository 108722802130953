import { render, staticRenderFns } from "./LeftMenuItem.vue?vue&type=template&id=6621562e&scoped=true"
import script from "./LeftMenuItem.vue?vue&type=script&lang=ts"
export * from "./LeftMenuItem.vue?vue&type=script&lang=ts"
import style0 from "./LeftMenuItem.vue?vue&type=style&index=0&id=6621562e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6621562e",
  null
  
)

component.options.__file = "LeftMenuItem.vue"
export default component.exports