import { IGridSettings, IGroupColumnSettings, ISortColumnSettings } from '@/interfaces/settings.interface';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import StringHelper from '@/utilities/string.helper';
import { ISortModuleState } from '../states/filter-state.interface';

@Module({ dynamic: true, store, name: 'sort' })
class SortModule extends VuexModule implements ISortModuleState {
    [key: string]: any;
    StockSortState: any;
    TaskSortState: any;
    UserSortState: any;
    IngoingOrderSortState: any;
    InventorySortState: any;
    JobSortState: any;
    LocationSortState: any;
    MaintenanceSortState: any;
    MaterialSortState: any;
    OperationSortState: any;
    OutgoingOrderSortState: any;
    SupplierSortState: any;
    DraftSortState: any;
    SalesOrderDraftSortState: any;

    @Mutation
    setJobSortState(dxState: any): void {
        this.JobSortState = dxState;
    }

    @Mutation
    setLocationSortState(dxState: any): void {
        this.LocationSortState = dxState;
    }

    @Mutation
    setMaintenanceSortState(dxState: any): void {
        this.MaintenanceSortState = dxState;
    }

    @Mutation
    setInventorySortState(dxState: any): void {
        this.InventorySortState = dxState;
    }

    @Mutation
    setMaterialSortState(dxState: any): void {
        this.MaterialSortState = dxState;
    }

    @Mutation
    setOperationSortState(dxState: any): void {
        this.OperationSortState = dxState;
    }

    @Mutation
    setIngoingOrderSortState(dxState: any): void {
        this.IngoingOrderSortState = dxState;
    }

    @Mutation
    setOutgoingOrderSortState(dxState: any): void {
        this.OutgoingOrderSortState = dxState;
    }

    @Mutation
    setStockSortState(dxState: any): void {
        this.StockSortState = dxState;
    }

    @Mutation
    setTaskSortState(dxState: any): void {
        this.TaskSortState = dxState;
    }

    @Mutation
    setUserSortState(dxState: any): void {
        this.UserSortState = dxState;
    }

    @Mutation
    setSupplierSortState(dxState: any): void {
        this.SupplierSortState = dxState;
    }

    @Mutation
    setDraftSortState(dxState: any): void {
        this.DraftSortState = dxState;
    }

    @Mutation
    setSalesOrderDraftSortState(dxState: any): void {
        this.SalesOrderDraftSortState = dxState;
    }

    @Mutation
    dispose(): void {
        this.StockSortState = null;
        this.TaskSortState = null;
        this.UserSortState = null;

        this.IngoingOrderSortState = null;
        this.InventorySortState = null;
        this.JobSortState = null;
        this.LocationSortState = null;
        this.MaintenanceSortState = null;
        this.MaterialSortState = null;
        this.OperationSortState = null;
        this.OutgoingOrderSortState = null;
        this.SupplierSortState = null;
        this.DraftSortState = null;
        this.SalesOrderDraftSortState = null; 
    }

    @Action
    async setSortColumnSettings(settings: ISortColumnSettings) {
        if (!settings) { return; }

        for (const key in settings) {
            if (settings.hasOwnProperty(key)) {
                const stateStr = settings[key];

                if (stateStr) {
                    try {
                        const state = JSON.parse(stateStr);

                        const getterKey = StringHelper.startWithUpperCase(key);
                        const setterKey = `set${getterKey}SortState`;

                        if (this.hasOwnProperty(setterKey)) {
                            this[setterKey](state);
                        }
                    } catch (error) { }
                }
            }
        }
    }

    get getMaterialSortColumn() {
        return this.MaterialSortState;
    }

    get getLocationSortColumn() {
        return this.LocationSortState;
    }

    get getJobSortColumn() {
        return this.JobSortState;
    }

    get getOutgoingOrderSortColumn() {
        return this.OutgoingOrderSortState;
    }

    get getStockSortColumn() {
        return this.StockSortState;
    }

    get getTaskSortColumn() {
        return this.TaskSortState;
    }
    get getOperationSortColumn() {
        return this.OperationSortState;
    }

    get getIngoingOrderSortColumn() {
        return this.IngoingOrderSortState;
    }

    get getSupplierSortColumn() {
        return this.SupplierSortState;
    }

    get getDraftSortColumn() {
        return this.DraftSortState;
    }

    get getInventorySortColumn() {
        return this.InventorySortState;
    }

    get getMaintenanceSortColumn() {
        return this.MaintenanceSortState;
    }

    get getSalesOrderDraftSortColumn() {
        return this.SalesOrderDraftSortState;
    }
}

export const sortModule = getModule(SortModule);
