import { IApportExtendedResponse, IApportResponse } from '@/interfaces/api.interface';
import { $modalService } from '@/services/custom-modal.service';
import { settingsModule } from '@/store/modules/settings.module';
import { NetworkError } from '@/utilities/error.extensions';
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import router from '../router';
import { $environment } from './environment.service';
import { decorateUserChoice } from '@/utilities/decorate-user-choice.function';
import { isUserChoice, UserChoiceType } from '@/interfaces/web-api/user-choice.interface';
import { StatusCodes } from 'http-status-codes';
import { $user } from './user.service';
import { IKeyValue } from '@/interfaces/common.interface';
import { $storage } from '@/services/storage.service';
import { isNullish } from '@/utilities/nullable.helper';
import { ErrorCode } from '@/interfaces/http.interface';

class ApiService {
    // tslint:disable-next-line:variable-name
    private _requestInfo: any = undefined;
    
    private axiosInstance: AxiosInstance | null = null;

    private abortController = new AbortController();

    private get isOnLoginPage(): boolean {
        const { currentRoute } = router;

        return !!currentRoute && (currentRoute.name === 'login' || currentRoute.fullPath === '/');
    }
    
    private http(): AxiosInstance {
        if (!this.axiosInstance) {
            this.axiosInstance = this.recreate();
        } else {
            const { defaults } = this.axiosInstance;
            if (!defaults || !defaults.baseURL) {
                this.axiosInstance = this.recreate();
            }
        }

        return this.axiosInstance;
    }
    
    private get headers() {
        if (!this.axiosInstance) { return {}; }

        const {headers} = this.axiosInstance.defaults;
        const excludedKeys = ['delete', 'get', 'head', 'patch', 'post', 'put', 'common'];
        
        const requestHeaders: IKeyValue<string> = {};

        for (const key in headers) {
           if (excludedKeys.includes(key)) { continue; }
        
           const header = (headers as any)[key] as AxiosRequestHeaders | undefined;
           Object.defineProperty(requestHeaders, key, { value: header });
        }

        if (!requestHeaders['Client-Version']) {
            Object.defineProperty(requestHeaders, 'Client-Version', { value: $environment.versionExtended });
        }
        
        return requestHeaders;
    }
    
    constructor() {
        const token = $storage.Token.get($user.displayName);

        if (token) {
            this.createWhenReady(token);
        } else {
            console.warn('Token missing!');
        }
    }

    dispose() {
        if (this.axiosInstance) {
            this.axiosInstance = null;
        }

        if (this._requestInfo) {
            this._requestInfo = undefined;
        }
    }

    get<T>(url: string, source?: any, showLoader: boolean = false, config?: AxiosRequestConfig, handleError = true): Promise<IApportResponse<T>> {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                this.wrapInLoader(
                    this.performGet.bind(this, url, config),
                    resolve,
                    reject,
                    source
                );
            } else {
                this.performGet(url, config)
                    .then(response => this.handleResolve(response, resolve, reject, source))
                    .catch(error => this.handleRequestError(error, handleError, source, reject));
            }
        });
    }

    post<T>(url: string, data: any, source: any, config?: AxiosRequestConfig, showLoader: boolean = true, handleError = true): Promise<IApportResponse<T>> {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                this.wrapInLoader(
                    this.performPost.bind(this, url, data, config),
                    resolve,
                    reject,
                    source
                );
            } else {
                this.performPost(url, data, config)
                    .then(response => this.handleResolve(response, resolve, reject, source))
                    .catch(error => this.handleRequestError(error, handleError, source, reject));
            }
        });
    }
    
    patch<T>(url: string, data: any, source: any, config?: AxiosRequestConfig, showLoader = true, handleError = true): Promise<IApportResponse<T>> {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                this.wrapInLoader(
                    this.performPatch.bind(this, url, data, config),
                    resolve,
                    reject,
                    source
                );
            } else {
                this.performPatch(url, data, config)
                    .then(response => this.handleResolve(response, resolve, reject, source))
                    .catch(error => this.handleRequestError(error, handleError, source, reject));
            }
        });
    }
    
    put<T>(url: string, data: any, source: any, config?: AxiosRequestConfig, showLoader = true, handleError = true, onUserChoiceCancel?: () => void): Promise<IApportExtendedResponse<T>> {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                this.wrapInLoader(
                    this.performPut.bind(this, url, data, config),
                    resolve,
                    reject,
                    source,
                    onUserChoiceCancel
                );
            } else {
                this.performPut(url, data, config)
                    .then(response => this.handleResolve(response, resolve, reject, source))
                    .catch(error => this.handleRequestError(error, handleError, source, reject));
            }
        });
    }

    delete<T>(url: string, source: any, data?: any, config?: AxiosRequestConfig, showLoader: boolean = true, handleError = true): Promise<IApportExtendedResponse<T>> {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                this.wrapInLoader(
                    this.performDelete.bind(this, url, data, config),
                    resolve,
                    reject,
                    source
                );
            } else {
                this.performDelete(url, data, config)
                    .then(response => this.handleResolve(response, resolve, reject, source))
                    .catch(error => this.handleRequestError(error, handleError, source, reject));
            }
        });
    }

    private wrapInLoader<T>(request: () => AxiosPromise<any>, resolve: any, reject: any, source: any, onUserChoiceCancel?: () => void) {
        settingsModule.setLoaderAction(1);

        request.call(this)
            .then(response => {
                settingsModule.setLoaderAction(-1);
                this.handleResolve<T>(response, resolve, reject, source);
            })
            .catch(error => {
                settingsModule.setLoaderAction(-1);
                this.handleError(error, source, onUserChoiceCancel);

                reject(error);
            });
    }
    private updateRequestConfig(config?: AxiosRequestConfig): AxiosRequestConfig{
        config = config ?? {};
        if (isNullish(config.signal)) {
            config.signal = this.abortController.signal;
        }

        return config;
    }
    private performGet(url: string, config?: AxiosRequestConfig) {
        this.setRequestInfo(url);
        config = this.updateRequestConfig(config);

        return this.http().get(url, config);
    }
    
    private performPost(url: string, data?: any, config?: AxiosRequestConfig) {
        this.setRequestInfo(url, data);
        config = this.updateRequestConfig(config);

        return this.http().post(url, data, config);
    }
    
    private performPatch(url: string, data?: any, config?: AxiosRequestConfig) {
        this.setRequestInfo(url, data);
        config = this.updateRequestConfig(config);

        return this.http().patch(url, data, config);
    }
    
    private performPut(url: string, data?: any, config?: AxiosRequestConfig) {
        this.setRequestInfo(url, data);
        config = this.updateRequestConfig(config);
        
        return this.http().put(url, data, config);
    }

    private performDelete(url: string, data?: any, config?: AxiosRequestConfig) {
        this.setRequestInfo(url);
        config = this.updateRequestConfig(config);

        if (data) {
            config = !!config ? config : {};
            config.data = data;
        }
        
        return this.http().delete(url, config);
    }

    private recreate(): AxiosInstance {
        const token = $storage.Token.get($user.displayName);

        this.axiosInstance = this.createInstance(token);
        
        return this.axiosInstance;
    }

    private createInstance(token: string) {
        const axiosInstance = axios.create({
            baseURL: $environment.apiURL,
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Client-Version': $environment.version
            }
        });
        
        axiosInstance.interceptors.request.use(
            req => {
                const axiosInstanceToken = req.headers!['Authorization'];
                const localStorageToken = $storage.Token.get($user.displayName);
                
                if (axiosInstanceToken !== localStorageToken) {
                    req.headers!['Authorization'] = localStorageToken;
                }
                
                if (req.url?.startsWith('http') && !req.url.includes(req.baseURL!)) {
                    // note: remove Authorization header for external requests
                    // token should be used only for internal requests to the API (for security reasons)
                    req.headers['Authorization'] = undefined;
                }
                return req;
            },
            error => {
                console.error("Request error:", error);
                this.abortController.abort();
            }
        );
    
        return axiosInstance;
    }

    private createWhenReady(token: string) {
        if ($environment.isReady) {
            this.axiosInstance = this.createInstance(token);
        } else {
            window.setTimeout(this.createWhenReady.bind(this, token), 500);
        }
    }
    
    private async handleError(error: any, source: any, onUserChoiceCancel?: () => void) {
        let userChoice = error;
        
        if (error.response) {
            const { data, status } = error.response;

            if (data && data.userChoice) {
                userChoice = data.userChoice;
            } else if (status === 403 || status === 401) {
                // todo: add message 'User token has expired or is not yet valid.'
                await $user.logout();

                if (!this.isOnLoginPage) {
                    return router.push({ name: 'login' });
                }
                return;
            }
        }

        if (isUserChoice(userChoice)) {
            return this.showUserChoice(userChoice, source, onUserChoiceCancel);
        }
        
        return $modalService.showError(new NetworkError(error));
    }
    
    private async showUserChoice(userChoice: any, source: any, onUserChoiceCancel?: () => void) {
        userChoice.source = source;
        userChoice.onCancel = onUserChoiceCancel;

        const {url, headers, body} = this._requestInfo;
        decorateUserChoice(userChoice, url, headers, body);
        
        return $modalService.showUserChoice(userChoice);
    }

    private handleResolve<T extends any>(response: AxiosResponse, resolve: any, reject: any, source: any) {

        const result: IApportExtendedResponse<T> = response.data;
        const { isUserChoice, userChoice } = result;
        
        if (response.status === StatusCodes.MULTI_STATUS) {
            Object.defineProperty(result, 'isMultiStatus',  {
                value: true,
                writable: false
            });
        }
        
        if (isUserChoice && userChoice) {
            const { type } = userChoice;

            switch (type) {
                case UserChoiceType.MaterialItemType:
                    // note: custom userChoice types should be handle in specific way
                    return reject(userChoice);
                default:
                    this.handleError(userChoice, source);
                    return reject(null); // note: null means error was handled already
            }
        }
        
        return resolve(result);
    }
    
    private handleRequestError(error: any, handleError: boolean, source: any, reject: any): void {
        if (error && error.code === ErrorCode.CANCELED) {
            // note: fail silently if request was canceled by user
            return;
        }

        if (handleError) {
            this.handleError(error, source);
        }
        reject(error);
    }
    
    private setRequestInfo(url: string, body: any = {}) {    
        const info = {
            url,
            headers: this.headers,
            body
        };
        
        this._requestInfo = info;
    }
}

export const $http = new ApiService();
