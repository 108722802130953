import { addSpaces } from '@/utilities/add-spaces.function';
import { removeSpaces } from '@/utilities/remove-spaces.function';
import { strRender } from './str-render.function';

export default class StringHelper {
    static addSpaces(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }

        return addSpaces(str);
    }

    static removeSpaces(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }

        return removeSpaces(str);
    }

    static replace(str: string, char: string, ...replacers: any[]) {
        if (!StringHelper.isValid(str)) { return ''; }

        return strRender(str, char, replacers);
    }

    static replaceAll(str: string, search: string, replaceWith: string) {
        if (!StringHelper.isValid(str)) { return ''; }

        return str.split(search).join(replaceWith);
    }

    static startWithLowerCase(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }

        return `${str[0].toLowerCase()}${str.substring(1)}`;
    }

    static startWithUpperCase(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }

        return `${str[0].toUpperCase()}${str.substring(1)}`;
    }

    static toPascalCase(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }

        let pattern = / (\w)/g;
        if (this.isKebabCase(str)) {
            pattern = /-(\w)/g;
        } else if (this.isSnakeCase(str)) {
            pattern = /_(\w)/g;
        }

        str = str.replace(pattern, (substring: string, ...args: any[]) => {
            return substring[1].toUpperCase();
        });

        return this.startWithUpperCase(str);
    }

    static toCamelCase(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }

        const pascalCase = this.toPascalCase(str);

        return this.startWithLowerCase(pascalCase);
    }

    static toNormalcase(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }

        const pascalCase = this.toPascalCase(str);

        return StringHelper.startWithUpperCase(pascalCase.split(/(?=[A-Z])/).join(' ').toLowerCase());
    }

    static toKebabCase(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }
        if (this.isKebabCase(str)) { return str; }

        const pascalCase = this.toPascalCase(str);
        const pattern = /([a-z0–9])([A-Z])/g;

        return pascalCase.replace(pattern, "$1-$2").toLowerCase();
    }

    static toSnakeCase(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }
        if (this.isSnakeCase(str)) { return str; }

        const pascalCase = this.toPascalCase(str);
        const pattern = /([a-z0–9])([A-Z])/g;

        return pascalCase.replace(pattern, "$1_$2").toLowerCase();
    }

    static toDateTimeStr(str: any) {
        if (!StringHelper.isValid(str)) { return str; }

        if (str.includes('T')) {
            // tslint:disable-next-line:prefer-const
            let [date, time] = str.split('T');

            if (time && time.length > 8) {
                time = time.substr(0, 8);
            }

            return `${date} ${time}`;
        }

        return str;
    }

    static isNumeric(value: any) {
        if (!value || typeof (value) !== 'string') {
            return false;
        }

        return value.trim() !== '' && isFinite(Number(value));
    }

    static isValid(obj: any) {
        if (!obj) {
            console.log(`StringHelper validation failed: got empty value`);
            return false;
        }

        if (typeof (obj) !== 'string') {
            console.log(`StringHelper validation failed: expected 'string' but got '${typeof (obj)}`, obj);
            return false;
        }

        return true;
    }

    static isPascalCase(text: string): boolean {
        if (!text || typeof (text) !== 'string') { return false; }

        if (text.includes('-')) {
            return false;
        }
        if (text[0].toUpperCase() !== text[0]) {
            return false;
        }

        return true;
    }

    static isCamelCase(text: string): boolean {
        if (!text || typeof (text) !== 'string') { return false; }

        if (text.includes('-')) {
            return false;
        }
        if (text[0].toUpperCase() === text[0]) {
            return false;
        }

        return true;
    }

    static isKebabCase(text: string): boolean {
        if (!text || typeof (text) !== 'string') { return false; }

        if (!text.includes('-')) {
            return false;
        }

        return true;
    }

    static isSnakeCase(text: string): boolean {
        if (!text || typeof (text) !== 'string') { return false; }

        if (!text.includes('_')) {
            return false;
        }

        return true;
    }

    static removeQueryParamsSpace(str: string) {
        if (!StringHelper.isValid(str)) { return ''; }
        const name = this.customDecodeURIComponent(str);
        return name.replace(/%20/g, ' ');;
    }

    static count(text: string, lookUp: string): number {
        let count = 0;
        let index = text.indexOf(lookUp);

        while (index > -1) {
            count++;
            index = text.indexOf(lookUp, index + 1);
        }

        return count;
    }
    private static customDecodeURIComponent(encodedString: string): string {
        try {
          return decodeURIComponent(encodedString.replace(/%(?![0-9A-Fa-f]{2})/g, '%25'));
        } catch (error: any) {
          return '';
        }
      }
}
