import { IAuthResponse } from '@/interfaces/web-api/login.interface';
import { IUser, IUserRole } from '@/interfaces/web-api/user.interface';
import { $http } from '@/services/http.service';
import { cookieService } from '@/services/cookie.service';
import { $i18N } from '@/services/i18n.service';
import { signalRService } from '@/services/signalR.service';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import { IUserState } from '../states/user-state.interface';
import { filterModule } from './filter.module';
import { filterColumnSettings } from './filterColumnSetting.module';
import { gridModule } from './grid.module';
import { modalModule } from './modal.module';
import { settingsModule } from './settings.module';
import { isDevelopment } from '@/utilities/is-development.function';
import { customGridModule } from './custom-grid.module';
import { $storage } from '@/services/storage.service';
import { SystemRoleEnum } from '@/interfaces/web-api/user-role.interface';

@Module({ dynamic: true, store, name: 'user' })
class UserModule extends VuexModule implements IUserState {
    isAuth: boolean | null = null;
    displayName: string = '';
    user: IUser | null = null;

    get getUser(): IUser | null {
        if (this.user) {
            return this.user;
        }

        const user: IUser | null = cookieService.getCookie<IUser>(cookieService.keys.USER);

        if (user) {
            store.commit('setUser', user);
        }
        
        return user;
    }

    get getRoles(): IUserRole[] {
        const user: IUser | null = this.getUser;
        
        if (!user) {
            return [];
        }
        
        return user.userRoles || [];
    }
    
    get getIsAuth(): boolean {
        if (this.isAuth === null) {
            store.dispatch('refreshState');
    
            return !!this.isAuth;
        } else {
            return this.isAuth;
        }
    }

    get getIsAdmin(): boolean {
        return this.getRoles.some(role => role.userRoleName === 'AdminRole' || role.systemRole === SystemRoleEnum.Administrator);
    }

    get UserRoleName(): string {
        return this.getRoles.map(role => role.userRoleName)?.join(', ');
    }

    @Mutation
    setUser(user: IUser | null): void  {
        if (this.user === user) { return; }
        
        this.user = user;
        this.displayName = !!user ? user.userName : '';
        
        cookieService.addCookie(cookieService.keys.USER, user);
    }

    @Mutation
    setAuth(isAuth: boolean): void {
        this.isAuth = isAuth;
    }

    @Mutation
    setUserCookie(user: IUser): void  {
        cookieService.addCookie(cookieService.keys.USER, user);
    }

    @Mutation
    deleteUserCookie(): void  {
        cookieService.deleteCookie(cookieService.keys.USER);
    }

    @Action
    disposeUser(): void {
        $storage.Token.remove(this.getUser?.userName ?? "");
        this.deleteUserCookie();
        this.setUser(null);
        this.setAuth(false);
    }

    @Action
    refreshState(): void {
        const token = $storage.Token.get(this.getUser?.userName ?? "");;

        if (!!token) {
            const user: IUser | null = this.getUser;
            this.setAuth(!!user);
            settingsModule.setEnabledFeaturesForToken(token);
        }
    }

    @Action
    async logout(): Promise<void> {
        cookieService.dispose();
        await signalRService.dispose();
        $http.dispose();
        
        this.disposeUser();
        settingsModule.disposeToken();
        gridModule.dispose();
        filterModule.dispose();
        modalModule.dispose();
        filterColumnSettings.dispose();
        customGridModule.dispose();
        $storage.Session.clear();
    }
    
    @Action
    login(response: IAuthResponse) {
        const user: IUser = {
            id: response.userId,
            userId: response.userId,
            userName: response.userName,
            userRoles: response.userRoles || [],
            isActive: true,
            defaultLanguage: response.defaultLanguage,
            password: '',
            deleted: false,
            loadCarriers: []
        };

        if (isDevelopment() && user.userName === 'admin' && !user.userRoles.some(r => r.userRoleName === 'GlobalAdminRole') ) {
            user.userRoles.push({
                userRoleName: 'GlobalAdminRole',
                deleted: false,
                id: '0',
                loadCarriers: [],
                wareHouseAreas: [],
                assignedUser: 0
            });
        }

        settingsModule.setUserSettings(response);
        this.setUserCookie(user);
        this.setUser(user);
        this.setAuth(!!user);
        $storage.Token.set(this.getUser?.userName ?? "", response.accessToken);
    }
}

export const userModule = getModule(UserModule);
