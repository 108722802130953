import { IUserChoice, IUserChoiceApiData, UserChoiceType } from '@/interfaces/web-api/user-choice.interface';
import { $i18N } from '@/services/i18n.service';

export class ApportError extends Error {
    context: any;
    title: string = '';
    
    constructor(message: string, context?: any, title?: string) {
        super(message);
        
        this.context = context || null;

        if (title) {
            this.title = title;
        }
    }
}

export class SignalRError extends Error {
    constructor(message: any) {
        super(message);
        this.name = 'SignalR Error';
    }
}

export class DevExtremeError extends Error {
    constructor(message: any) {
        super(message);
        this.name = 'DevExtreme Error';
    }
}

export class NetworkError extends Error {
    constructor(message: any) {
        super(message);
        this.name = 'Network Error';
        
        this.getExtraInfo();
    }
    
    get networkConnection() {
        const {connection, mozConnection, webkitConnection} = window.navigator as any;
        
        return connection || mozConnection || webkitConnection || false;
    }
    
    getExtraInfo() {
        if (this.networkConnection) {
            console.log('NetworkError connection info:', this.networkConnection);
        }
    }
}

export class AuthorizationError extends ApportError {
    constructor() {
        super('Authorization Error: access denied');
    }
}

export class UserChoiceTypeError extends Error implements IUserChoice {
    title: string;
    description: string;
    details: string;
    version: string;
    exception: any;
    timeStamp: Date;
    userOptions: any[];
    id: string;
    deleted: boolean;
    apiData?: IUserChoiceApiData;
    isInformationOnly: boolean;
    type = UserChoiceType.Standard;
    
    constructor(message: any, apiData?: IUserChoiceApiData) {
        super(message);
        
        this.title = '';
        this.description = message;
        this.details = '';
        this.version = '';
        this.exception = null;
        this.timeStamp = new Date();
        this.userOptions = [];
        this.id = '';
        this.deleted = false;
        this.apiData = apiData;
        this.isInformationOnly = false;
    }
    source?: any;
}

export class TooManyStocksError extends ApportError {
    constructor() {
        super($i18N.translate('Dialogs.TooManyStocks.Message').value);

        this.title = $i18N.translate('Dialogs.TooManyStocks.Title').value;
    }
}
