import Vue from 'vue';
import Router, { Route } from 'vue-router';
import DashboardPage from '@/views/pages/dashboard/DashboardPage.vue';
import Layout from '@/views/Layout.vue';
import store from './store/index';
import { settingsModule } from './store/modules/settings.module';
import { $user } from './services/user.service';
import { $system } from './services/system.service';
import { $modalService } from './services/custom-modal.service';
import { AuthorizationError } from './utilities/error.extensions';
import { $permission } from './services/permission.service';
import { FeatureFlag } from './interfaces/web-api/feature-fag.interfaces';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            name: '',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'dashboard',
                    component: DashboardPage
                },
                {
                    path: 'operations-page',
                    name: 'operations-page',
                    component: () => import('./views/pages/operations/OperationsPage.vue'),
                    meta: {
                        apiViewName: 'Operation'
                    }
                },
                {
                    path: 'jobs-page',
                    name: 'jobs-page',
                    component: () => import('./views/pages/jobs/JobsPage.vue'),
                    meta: {
                        apiViewName: 'Job'
                    }
                },
                {
                    path: 'tasks-page',
                    name: 'tasks-page',
                    component: () => import('./views/pages/tasks/TasksPage.vue'),
                    meta: {
                        apiViewName: 'Task'
                    }
                },
                {
                    path: 'maintenance-page',
                    name: 'maintenance-page',
                    component: () => import('./views/pages/maintenance/MaintenancePage.vue'),
                    meta: {
                        apiViewName: 'Maintenance'
                    }
                },
                {
                    path: 'inventory-page',
                    name: 'inventory-page',
                    component: () => import('./views/pages/inventory/InventoryPage.vue'),
                    meta: {
                        apiViewName: 'Inventory'
                    }
                },
                {
                    path: 'stocks-page',
                    name: 'stocks-page',
                    component: () => import('./views/pages/stocks/StocksPage.vue'),
                    meta: {
                        apiViewName: 'Stock'
                    }
                },
                {
                    path: 'outgoing-orders-page',
                    name: 'outgoing-orders-page',
                    component: () => import('./views/pages/outgoing-orders/OutgoingOrdersPage.vue'),
                    meta: {
                        apiViewName: 'OutgoingOrder'
                    }
                },
                {
                    path: 'ingoing-orders-page',
                    name: 'ingoing-orders-page',
                    component: () => import('./views/pages/ingoing-orders/IngoingOrdersPage.vue'),
                    meta: {
                        apiViewName: 'IngoingOrder'
                    }
                },
                {
                    path: 'locations-page',
                    name: 'locations-page',
                    component: () => import('./views/pages/locations/LocationsPage.vue'),
                    meta: {
                        apiViewName: 'Location'
                    }
                },
                {
                    path: 'materials-page',
                    name: 'materials-page',
                    component: () => import('./views/pages/materials/MaterialsPage.vue'),
                    meta: {
                        apiViewName: 'Material'
                    }
                },
                {
                    path: 'materials-es-page',
                    name: 'materials-es-page',
                    component: () => import('./views/pages/materials-es/MaterialsEsPage.vue'),
                    meta: {
                        apiViewName: 'Material',
                        requiredEnabledFeature: FeatureFlag.MaterialsServerSidePaging
                    }
                },
                {

                    path: 'reports-page',
                    name: 'reports-page',
                    component: () => import('./views/pages/reports/ReportsPage.vue'),
                    meta: {
                        apiViewName: 'Reports',
                        requiredEnabledFeature: FeatureFlag.ReportsServerSidePaging
                    }
                },
                {
                    path: 'material-summary-report-page',
                    name: 'material-summary-report-page',
                    component: () => import('./views/pages/reports/material-summary/MaterialSummaryReportPage.vue'),
                    meta: {
                        apiViewName: 'Report',
                        requiredEnabledFeature: FeatureFlag.MaterialSummary
                    }
                },
                {
                    path: 'material-transaction-report-page',
                    name: 'material-transaction-report-page',
                    component: () => import('./views/pages/reports/material-transaction/MaterialTransactionReportPage.vue'),
                    meta: {
                        apiViewName: 'Report',
                        requiredEnabledFeature: FeatureFlag.MaterialTransaction
                    }
                },
                {
                    path: 'slow-moving-materials-report-page',
                    name: 'slow-moving-materials-report-page',
                    component: () => import('./views/pages/reports/SlowMovingMaterials/SlowMovingMaterialReportPage.vue'),
                    meta: {
                        apiViewName: 'Report',
                        requiredEnabledFeature: FeatureFlag.SlowMovingMaterialsOverView
                    }
                },
                {
                    path: 'slow-moving-materials-config-page',
                    name: 'slow-moving-materials-config-page',
                    component: () => import('./views/pages/reports/SlowMovingMaterials/SlowMovingMaterialConfigPage.vue'),
                    meta: {
                        apiViewName: 'Report',
                        requiredEnabledFeature: FeatureFlag.SlowMovingMaterialsOverView
                    }
                },
                {
                    path: 'material-transaction-config-page',
                    name: 'material-transaction-config-page',
                    component: () => import('./views/pages/reports/material-transaction/MaterailTransactionConfigPage.vue'),
                    meta: {
                        apiViewName: 'Report',
                        requiredEnabledFeature: FeatureFlag.MaterialTransaction
                    }
                },
                {
                    path: 'company-information',
                    name: 'company-information',
                    component: () => import('./views/settings/company-information/CompanyInfoPage.vue'),
                    meta: {
                        apiViewName: '',
                        isCustom: true,
                        title: 'Company Information',
                        permissions: ['header', 'settings', 'users']
                    }
                },
                {
                    path: 'users',
                    name: 'users',
                    component: () => import('./views/settings/user-management/UserManagementPage.vue'),
                    meta: {
                        apiViewName: '',
                        isCustom: true,
                        title: 'User Management',
                        permissions: ['header', 'settings', 'users']
                    }
                },
                {
                    path: 'import',
                    name: 'import',
                    component: () => import('./views/settings/import-data/ImportDataPage.vue'),
                    meta: {
                        apiViewName: '',
                        isCustom: true,
                        title: 'Import Data',
                        permissions: ['header', 'settings', 'import']
                    }
                },
                {
                    path: 'integration',
                    name: 'integration',
                    component: () => import('./views/settings/integration-monitor/IntegrationMonitor.vue'),
                    meta: {
                        apiViewName: '',
                        isCustom: true,
                        title: 'Integration Monitor',
                        permissions: ['header', 'settings', 'integration_monitor']
                    }
                },
                {
                    path: 'kpi-management',
                    name: 'kpi-management',
                    component: () => import('./views/settings/kpi-management/KpiManagement.vue'),
                    meta: {
                        apiViewName: '',
                        isCustom: true,
                        title: 'KPI Management',
                        permissions: ['header', 'settings', 'kpi']
                    }
                },
                {
                    path: 'cause-codes',
                    name: 'cause-codes',
                    component: () => import('./views/settings/cause-codes/CauseCodesPage.vue'),
                    meta: {
                        apiViewName: 'CauseCodes',
                        isCustom: true,
                        title: 'Cause Codes',
                        permissions: ['header', 'settings', 'cause_codes']
                    }
                },
                {
                    path: 'printer-management',
                    name: 'printer-management',
                    component: () => import('./views/settings/printer-management/PrinterManagement.vue'),
                    meta: {
                        apiViewName: 'Printer',
                        isCustom: true,
                        title: 'Printer Management',
                        permissions: ['header', 'settings', 'printers']
                    }
                },
                {
                    path: 'downloads',
                    name: 'downloads',
                    component: () => import('./views/settings/downloads/DownloadsPage.vue'),
                    meta: {
                        isCustom: true,
                        title: 'Downloads',
                        permissions: ['header', 'settings', 'downloads']
                    }
                },
                {
                    path: 'user-settings',
                    name: 'user-settings',
                    component: () => import('./views/settings/user-settings/UserSettingsPage.vue'),
                    meta: {
                        isCustom: true,
                        title: 'User Settings',
                        permissions: ['header', 'settings', 'user_settings']
                    }
                },
                {
                    path: 'stock-attributes',
                    name: 'stock-attributes',
                    component: () => import('./views/settings/stock-attributes/StockAttributes.vue'),
                    meta: {
                        isCustom: true,
                        title: 'Stock Attributes',
                        permissions: ['header', 'settings', 'stock_attributes']
                    }
                },
                {
                    path: 'active-users',
                    name: 'active-users',
                    component: () => import('./views/settings/active-users/ActiveUsers.vue'),
                    meta: {
                        isCustom: true,
                        title: 'Active Users',
                        permissions: ['header', 'settings', 'active_users']
                    }
                },
                {
                    path: 'supplier-page',
                    name: 'supplier-page',
                    component: () => import('./views/pages/purchases/suppliers/SupplierPage.vue'),
                    meta: {
                        apiViewName: 'Supplier'
                    }
                },
                {
                    path: 'supplier-es-page',
                    name: 'supplier-es-page',
                    component: () => import('./views/pages/purchases/suppliers-es/SupplierEsPage.vue'),
                    meta: {
                        apiViewName: 'Supplier',
                        requiredEnabledFeature: FeatureFlag.PurchaseModule
                    }
                },
                {
                    path: 'newdraft-page',
                    name: 'newdraft-page',
                    component: () => import('./views/pages/purchases/new-draft/NewDraftPage.vue'),
                    meta: {
                        requiredEnabledFeature: FeatureFlag.PurchaseModule
                    }
                },
                {
                    path: 'emptydraft-page',
                    name: 'emptydraft-page',
                    component: () => import('./views/pages/purchases/empty-draft/EmptyDraftPage.vue'),
                    meta: {
                        requiredEnabledFeature: FeatureFlag.PurchaseModule
                    }
                },
                {
                    path: 'draft-es-page',
                    name: 'draft-es-page',
                    component: () => import('./views/pages/purchases/saved-draft-es/DraftEsPage.vue'),
                    meta: {
                        apiViewName: 'Draft',
                        requiredEnabledFeature: FeatureFlag.PurchaseModule
                    }
                },
                {
                    path: 'draft-page',
                    name: 'draft-page',
                    component: () => import('./views/pages/purchases/saved-draft/DraftPage.vue'),
                    meta: {
                        apiViewName: 'Draft',
                        requiredEnabledFeature: FeatureFlag.PurchaseModule
                    }
                },
                {
                    path: 'updatedraft-page',
                    name: 'updatedraft-page',
                    component: () => import('./views/pages/purchases/update-draft/UpdateDraft.vue'),
                    meta: {
                        apiViewName: 'UpdateDraft',
                        requiredEnabledFeature: FeatureFlag.PurchaseModule
                    }
                
                },
                {
                    path: 'integration-imports',
                    name: 'integration-imports',
                    component: () => import('./views/settings/integration/imports/IntegrationImportsPage.vue'),
                    meta: {
                        isCustom: true,
                        title: 'Integration Imports',
                        permissions: ['header', 'settings', 'integration']
                    }
                },
                {
                    path: 'integration-messages',
                    name: 'integration-messages',
                    component: () => import('./views/settings/integration/messages/IntegrationMessagesPage.vue'),
                    meta: {
                        isCustom: true,
                        title: 'Integration Messages',
                        permissions: ['header', 'settings', 'integration']
                    }
                },
                {
                    path: 'integration-requests',
                    name: 'integration-requests',
                    component: () => import('./views/settings/integration/requests/IntegrationRequestsPage.vue'),
                    meta: {
                        isCustom: true,
                        title: 'Integration Requests',
                        permissions: ['header', 'settings', 'integration']
                    }
                },
                {
                    path: 'tasks-es-page',
                    name: 'tasks-es-page',
                    component: () => import('./views/pages/tasks-es/TasksEsPage.vue'),
                    meta: {
                        apiViewName: 'Tasks',
                        requiredEnabledFeature: FeatureFlag.TasksServerSidePaging
                    }
                },
                {
                    path: 'stocks-es-page',
                    name: 'stocks-es-page',
                    component: () => import('./views/pages/stock-es/StockEsPage.vue'),
                    meta: {
                        apiViewName: 'Stock',
                        requiredEnabledFeature: FeatureFlag.StockServerSidePaging
                    }
                },
                {
                    path: 'locations-es-page',
                    name: 'locations-es-page',
                    component: () => import('./views/pages/locations-es/LocationEsPage.vue'),
                    meta: {
                        apiViewName: 'location',
                        requiredEnabledFeature: FeatureFlag.LocationServerSidePaging
                    }
                },
                {
                    path: 'jobs-es-page',
                    name: 'jobs-es-page',
                    component: () => import('./views/pages/jobs-es/JobsEsPage.vue'),
                    meta: {
                        apiViewName: 'Job',
                        requiredEnabledFeature: FeatureFlag.JobServerSidePaging
                    }
                },
                {
                    path: 'outgoing-orders-es-page',
                    name: 'outgoing-orders-es-page',
                    component: () => import('./views/pages/outgoing-orders-es/OutgoingOrdersEsPage.vue'),
                    meta: {
                        apiViewName: 'OutgoingOrderes',
                        requiredEnabledFeature: FeatureFlag.OutgoingOrderServerSidePaging
                    }
                },
                {
                    path: 'ingoing-orders-es-page',
                    name: 'ingoing-orders-es-page',
                    component: () => import('./views/pages/ingoing-orders-es/IngoingOrdersEsPage.vue'),
                    meta: {
                        apiViewName: 'IngoingOrderes',
                        requiredEnabledFeature: FeatureFlag.IngoingOrderServerSidePaging
                    }
                },
                {
                    path: 'operations-es-page',
                    name: 'operations-es-page',
                    component: () => import('./views/pages/operations-es/OperationsEsPage.vue'),
                    meta: {
                        apiViewName: 'Operationes',
                        requiredEnabledFeature: FeatureFlag.OperationServerSidePaging

                    }
                },
                {
                    path: 'inventory-es-page',
                    name: 'inventory-es-page',
                    component: () => import('./views/pages/inventory-es/InventoryEsPage.vue'),
                    meta: {
                        apiViewName: 'Inventoryes',
                        requiredEnabledFeature: FeatureFlag.InventoryServerSidePaging
                    }
                },
                {
                    path: 'maintenance-es-page',
                    name: 'maintenance-es-page',
                    component: () => import('./views/pages/maintenance-es/MaintenanceEsPage.vue'),
                    meta: {
                        apiViewName: 'Maintenance',
                        requiredEnabledFeature: FeatureFlag.StockServerSidePaging
                    }
                },
                {
                    path: 'create-sales-order',
                    name: 'create-sales-order',
                    component: () => import('./views/pages/sales-order/create-sales-order/CreateSalesOrderPage.vue'),
                    meta: {
                        apiViewName: 'CreateSalesOrder'
                    }
                },
                {
                    path: 'sales-order-draft',
                    name: 'sales-order-draft',
                    component: () => import('./views/pages/sales-order/sales-order-draft/SalesOrderDraftPage.vue'),
                    meta: {
                        apiViewName: 'SalesOrderDraft',
                        requiredEnabledFeature: FeatureFlag.CreateSalesOrderFromCockpit
                    }
                },
                {
                    path: 'user-management',
                    name: 'user-management',
                    component: () => import('./views/settings/user-role-management/UserRoleManagementPage.vue'),
                    meta: {
                        apiViewName: '',
                        isCustom: true,
                        title: 'User Management',
                        permissions: ['header', 'settings', 'users'],
                    }
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Login.vue')
        },
        {
            path: '/login-internal',
            name: 'login-internal',
            component: () => import('./views/LoginInternal.vue')
        },
        {
            path: '**',
            name: 'not-found',
            component: DashboardPage
        }
    ],
});

export type extendedRoute = Route & {
    meta: {requiredEnabledFeature: FeatureFlag}
}

router.beforeEach((to, from, next) => {
    const canAccessRoute = (route: extendedRoute | Route) => {
        const { meta } = route;

        if (meta?.requiredEnabledFeature) {
            return settingsModule.hasFeature(meta?.requiredEnabledFeature);
        }

        if (!meta || !meta.permissions) {
            return true;
        }

        return $permission.hasFor(...meta.permissions);
    };

    const allowedWithoutAuth = ['login', 'login-internal'];
    if (allowedWithoutAuth.includes(to.name || '')) {
        return next();
    }

    const { isAuth } = $user;
    if (!isAuth) {
        return next('/login');
    }

    $system.track();
    if (canAccessRoute(to)) {
        next();
    } else {
        $modalService.showError(new AuthorizationError());
    }
});

router.beforeResolve(async (to, from, next) => {
    // prefetch data specified in an async static function 'asyncData'

    const matched = router.getMatchedComponents(to);
    const prevMatched = router.getMatchedComponents(from);

    let diffed = false;
    const activated = matched.filter((c, i) => {
        return diffed || (diffed = (prevMatched[i] !== c));
    });

    if (!activated.length) {
        return next();
    }

    try {
        settingsModule.setLoaderRoute(1);

        const ready = await Promise.all(activated.map((component: any) => {
            if (component.asyncData) {
                return component.asyncData({ store, route: to });
            }
            // todo: modify it
            const parent: any = component.super;
            if (parent && parent.asyncData) {
                return parent.asyncData({ store, route: to });
            }
        }));
    } catch (error) {
        console.log(error);
    }

    settingsModule.setLoaderRoute(-1);
    next();
});


export default router;
