
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import StringHelper from '@/utilities/string.helper';
import { IGroupModuleState } from '../states/filter-state.interface';
import { IGroupColumnSettings } from '@/interfaces/settings.interface';

@Module({ dynamic: true, store, name: 'group' })
class GroupModule extends VuexModule implements IGroupModuleState {
    [key: string]: any;
    StockGroupState: any;
    TaskGroupState: any;
    UserGroupState: any;
    IngoingOrderGroupState: any;
    InventoryGroupState: any;
    JobGroupState: any;
    LocationGroupState: any;
    MaintenanceGroupState: any;
    MaterialGroupState: any;
    OperationGroupState: any;
    OutgoingOrderGroupState: any;
    SupplierGroupState: any;
    DraftGroupState: any;
    SalesOrderDraftGroupState: any;

    @Mutation
    setJobGroupState(dxState: any): void {
        this.JobGroupState = dxState;
    }

    @Mutation
    setLocationGroupState(dxState: any): void {
        this.LocationGroupState = dxState;
    }

    @Mutation
    setMaintenanceGroupState(dxState: any): void {
        this.MaintenanceGroupState = dxState;
    }

    @Mutation
    setInventoryGroupState(dxState: any): void {
        this.InventoryGroupState = dxState;
    }

    @Mutation
    setMaterialGroupState(dxState: any): void {
        this.MaterialGroupState = dxState;
    }

    @Mutation
    setOperationGroupState(dxState: any): void {
        this.OperationGroupState = dxState;
    }

    @Mutation
    setIngoingOrderGroupState(dxState: any): void {
        this.IngoingOrderGroupState = dxState;
    }

    @Mutation
    setOutgoingOrderGroupState(dxState: any): void {
        this.OutgoingOrderGroupState = dxState;
    }

    @Mutation
    setStockGroupState(dxState: any): void {
        this.StockGroupState = dxState;
    }

    @Mutation
    setTaskGroupState(dxState: any): void {
        this.TaskGroupState = dxState;
    }

    @Mutation
    setUserGroupState(dxState: any): void {
        this.UserGroupState = dxState;
    }

    @Mutation
    setSupplierGroupState(dxState: any): void {
        this.SupplierGroupState = dxState;
    }

    @Mutation
    setDraftGroupState(dxState: any): void {
        this.DraftGroupState = dxState;
    }

    @Mutation
    setSalesOrderDraftGroupState(dxState: any): void {
        this.SalesOrderDraftGroupState = dxState;
    }

    @Mutation
    dispose(): void {
        this.StockGroupState = null;
        this.TaskGroupState = null;
        this.UserGroupState = null;

        this.IngoingOrderGroupState = null;
        this.InventoryGroupState = null;
        this.JobGroupState = null;
        this.LocationGroupState = null;
        this.MaintenanceGroupState = null;
        this.MaterialGroupState = null;
        this.OperationGroupState = null;
        this.OutgoingOrderGroupState = null;
        this.SupplierGroupState = null;
        this.DraftGroupState = null;
        this.SalesOrderDraftGroupState = null;
    }

    @Action
    async setGroupColumnSettings(settings: IGroupColumnSettings) {
        if (!settings) { return; }

        for (const key in settings) {
            if (settings.hasOwnProperty(key)) {
                const stateStr = settings[key];

                if (stateStr) {
                    try {
                        const state = JSON.parse(stateStr);

                        const getterKey = StringHelper.startWithUpperCase(key);
                        const setterKey = `set${getterKey}GroupState`;

                        if (this.hasOwnProperty(setterKey)) {
                            this[setterKey](state);
                        }
                    } catch (error) { }
                }
            }
        }
    }

    get getMaterialGroupColumn() {
        return this.MaterialGroupState;
    }

    get getLocationGroupColumn() {
        return this.LocationGroupState;
    }

    get getJobGroupColumn() {
        return this.JobGroupState;
    }

    get getOutgoingOrderGroupColumn() {
        return this.OutgoingOrderGroupState;
    }
    
    get getStockGroupColumn() {
        return this.StockGroupState;
    }

    get getTaskGroupColumn() {
        return this.TaskGroupState;
    }

    get getOperationGroupColumn() {
        return this.OperationGroupState;
    }

    get getIngoingOrderGroupColumn() {
        return this.IngoingOrderGroupState;
    }

    get getSupplierGroupColumn() {
        return this.SupplierGroupState;
    }

    get getDraftGroupColumn() {
        return this.DraftGroupState;
    }

    get getInventoryGroupColumn() {
        return this.InventoryGroupState;
    }

    get getMaintenanceGroupColumn() {
        return this.MaintenanceGroupState;
    }

    get getSalesOrderDraftGroupColumn() {
        return this.SalesOrderDraftGroupState;
    }
}

export const groupModule = getModule(GroupModule);
