import { render, staticRenderFns } from "./LeftMenu.vue?vue&type=template&id=4c68c626&scoped=true"
import script from "./LeftMenu.vue?vue&type=script&lang=ts"
export * from "./LeftMenu.vue?vue&type=script&lang=ts"
import style0 from "./LeftMenu.vue?vue&type=style&index=0&id=4c68c626&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c68c626",
  null
  
)

component.options.__file = "LeftMenu.vue"
export default component.exports