







import { Component, Vue } from 'vue-property-decorator';

import CustomModals from '@/components/modals/CustomModals.vue';

@Component({
    components: {
        CustomModals
    }
})
export default class ApportApp extends Vue {}

