


















import { Component, Vue, Watch } from 'vue-property-decorator';

import DynamicModal from '@/components/modals/DynamicModal.vue';
import { ICustomModalConfig } from '@/interfaces/custom-modal.interface';
import { modalModule } from '../../store/modules/modal.module';

interface IState {
    isVisible: boolean;
    modals: ICustomModalConfig[];
}

@Component({
    components: {
        DynamicModal
    }
})
export default class CustomModals extends Vue {
    private state: IState = {
        isVisible: false,
        modals: []
    };

    @Watch('modals') onModal(newVal: ICustomModalConfig[]): void {
        if (newVal.length > 0) {
            this.show();
        } else {
            this.close();
        }
    }

    mounted(): void {
        if (this.modals.length > 0) {
            this.show();
        }
    }
    
    get showLoader(): boolean {
        const show: boolean = this.$loader.modalLoader();

        return show;
    }

    private show(): void {
        this.state.isVisible = true;
    }

    private close(): void {
        this.state.isVisible = false;
    }

    private get modals(): ICustomModalConfig[] {
        this.state.modals = modalModule.modals;

        return this.state.modals;
    }
}
