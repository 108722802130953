import { GUID } from "@/utilities/guid.extension";

export enum SystemRoleEnum {
    TemporaryUser,
    User,
    SuperUser,
    Administrator,
    SupportUser,
    ApportUser,
    AutomatedUser,
    ExternalAutomatedUser,
    GlobalAdminUser
}

export interface IRoleRequest {
    name: string;
    allowedLoadCarrierTypes?: string[];
    allowedLocationRoleGroups?: string[];
    systemRole?: SystemRoleEnum;
    maxNumberProcessesInJob?: number;
    maxNumberOfContainers?: number;
    userRoleId?: GUID;
}

export enum UserRoleEnum {
    User = 'User',
    Role = 'Role'
}