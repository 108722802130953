

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LeftMenuIcon extends Vue {
    @Prop({ type: String }) name!: string;
}
