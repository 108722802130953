import UrlService from '@/services/url.service';
import { CustomViewCurrent,  CustomViewDefault,  ICustomViewResult } from '@/interfaces/custom-views.interface';
import StringHelper from '@/utilities/string.helper';
import { $webApi } from './web-api.service';
import { $modalService } from './custom-modal.service';
import { customGridModule } from '@/store/modules/custom-grid.module';

class CustomViewsService {
    private readonly customViewSuffix = '_CustomView';
    private readonly gridSettingsSuffix = '_GridSettings';

    private readonly excludedQueryParams: string[] = [
        'custom-view',
        'toDate'
    ];

    get current(): string {
        const name = new UrlService(window.location).customViewName;
        
        return name || this.CustomViewName;
    }

    clearUrl(): void {
        const { hash } = window.location;
        
        if (!hash.includes('custom-view=')) {
            return;
        }
        
        const idx = hash.indexOf(`custom-view=${this.current}`) - 1;
        window.location.replace(hash.substring(0, idx));
    }
    
    customViewOwner(apiViewName: string): string {
        return StringHelper.toCamelCase(`${apiViewName}${this.customViewSuffix}`);
    }

    customViewUrl(customName: string): string {
        const customViewNames = this.CustomViewName;
        if (customName === customViewNames) {
            return window.location.hash;
        }

        const url = customName === customViewNames
                    ? this.stripAllQueryParams(window.location.hash)
                    : this.stripExcludedQueryParams(window.location.hash);

        const prefix = url.includes('?') ? '&' : '?';
        const customViewName = customName.replace(/%20/g, ' ');
        
        return `${url}${prefix}custom-view=${customViewName}`;
    }

    async getCustomView(apiViewName: string, customName: string): Promise<ICustomViewResult> {

        const customViewNames = this.CustomViewName;
        if (customName === customViewNames) {
            return await this.getCurrentSettings(apiViewName);
        }
        const cvResult: ICustomViewResult = {
            url: '',
            gridSettings: ''
        };

        const owner = this.customViewOwner(apiViewName);

        const result = await $webApi.GET.User.CockpitSettingsFor(owner);
        const name = StringHelper.removeQueryParamsSpace(customName);

        if (result && result.hasOwnProperty(owner)) {
            const viewSettings: any = result[owner];
            const foundKeyForGrid = this.findKeyIgnoreCase(this.gridSettingsName(name),viewSettings);
            cvResult.gridSettings = viewSettings[foundKeyForGrid];
            const foundKeyForView = this.findKeyIgnoreCase(this.customViewName(name),viewSettings);
            cvResult.url = viewSettings[foundKeyForView];
            if (cvResult.gridSettings) {
                customGridModule.setCustomState({ apiViewName, dxState: cvResult.gridSettings });
                if (!cvResult.url || !cvResult.gridSettings) {
                    throw new Error(`Could not load "${name}" custom view [Incomplete data]`);
                }
            }
        }
        return cvResult;
    }

    private async getCurrentSettings(apiViewName: string): Promise<ICustomViewResult> {
        const simpleUrl = window.location.hash.substring(0, window.location.hash.indexOf('?'));

        const cvResult: ICustomViewResult = {
            url: simpleUrl,
            gridSettings: ''
        };

        const result = await $webApi.GET.User.GridSettingsFor(apiViewName);
        const { gridSettings } = result;
        const viewName = StringHelper.toCamelCase(apiViewName);
        
        if (gridSettings && gridSettings.hasOwnProperty(viewName)) {
            cvResult.gridSettings = gridSettings[viewName] as  string;
        }
    
        return cvResult;
    }
    
    async getCustomViewsFor(apiViewName: string): Promise<string[]> {
        const owner = this.customViewOwner(apiViewName);

        const result = await $webApi.GET.User.CockpitSettingsFor(owner);

        const customViewNames = [this.CustomViewName];

        if (result && result.hasOwnProperty(owner)) {
            for (const key in result[owner]) {
                if (key.endsWith(this.customViewSuffix)) {
                    const getNameArray = result[owner][key].match(/custom-view=(.+)/);
                    const getName = getNameArray ? getNameArray[1]: null;
                    if(getName != null) {
                        customViewNames.push(getName);
                    }
                }
            }
        }

        return customViewNames;
    }

    async saveDefaultView(apiViewName: string, gridSettings: string): Promise<void> {
        const customName = this.CustomViewName;
        const gridPromise = this.saveCustomViewGridState(apiViewName, gridSettings, customName);

        const urlPromise = this.saveCurrentUrl(apiViewName, customName);
        
        await Promise.all([gridPromise, urlPromise]);
    }
    
    async saveCustomView(apiViewName: string, gridSettings: string, customName: string, saveForAll: boolean = false) {
        const gridPromise = this.saveCustomViewGridState(apiViewName, gridSettings, customName);

        const urlPromise = this.saveCurrentUrl(apiViewName, customName, saveForAll);

        await Promise.all([gridPromise, urlPromise]);

        window.location.replace(this.customViewUrl(customName));

        $modalService.showSuccess();

        setTimeout(() => {
            window.location.reload();
        }, 250);
    }
    
    async deleteCustomView(apiViewName: string, customName: string) {
        const url = this.customViewUrl(customName);

        return $webApi.DELETE.User.Settings(this.customViewOwner(apiViewName), this.customViewName(customName), url);
    }
    
    private async saveCustomViewGridState(apiViewName: string, gridSettings: string, customName: string) {
        return $webApi.PUT.User.CockpitSettings(this.customViewOwner(apiViewName), this.gridSettingsName(customName), gridSettings);
    }
    
    private async saveCurrentUrl(apiViewName: string, customName: string, saveForAll: boolean = false) {
        const url = this.customViewUrl(customName);
        if(saveForAll) {
            return $webApi.PUT.User.CockpitSettingsForAll(this.customViewOwner(apiViewName), this.customViewName(customName), url);
        }

        return $webApi.PUT.User.CockpitSettings(this.customViewOwner(apiViewName), this.customViewName(customName), url);
    }

    private stripAllQueryParams(url: string): string {
        const idx = url.indexOf('?');
        return idx === -1 ? url : url.substring(0, idx);
    }
    
    private stripExcludedQueryParams(url: string): string {
        this.excludedQueryParams.forEach(queryParam => {
            let idx = url.indexOf(queryParam);

            if (idx > -1) {   
                let end = url.indexOf('&', idx + 1);
                end = end === -1 ? url.length : (end + 1);
                
                const query = url.substring(idx, end);
                
                url = url.replace(query, '');
            }
        });

        return url.endsWith('?') ? url.substring(0, url.length - 1) : url;
    }

    private findKeyIgnoreCase(name:any,obj:any)
    {
        for(var key in obj)
        {
            if(key.toLowerCase() === name.toLowerCase())
            {
                return key;
            }
        }
        return "";
    }

    private gridSettingsName(customName: string): string {
        return `${customName}${this.gridSettingsSuffix}`;
    }

    private customViewName(customName: string): string {
        return `${customName}${this.customViewSuffix}`;
    }

    async saveCustomScopeView(apiViewName: string, gridSettings: string, customName: string, scope: string, _saveForMe: boolean, saveForAll: boolean) {
        const gridPromise = this.saveCustomViewScopeGridState(apiViewName, gridSettings, customName,scope);

        const urlPromise = this.saveCurrentUrl(apiViewName, customName, saveForAll);

        await Promise.all([gridPromise, urlPromise]);

        window.location.replace(this.customViewUrl(customName));
        $modalService.showSuccess();

        setTimeout(() => {
            window.location.reload();
        }, 250);
    }

    private async saveCustomViewScopeGridState(apiViewName: string, gridSettings: string, customName: string, scope: string) {
        return $webApi.POST.Seeding.Grid_ScopeUnitSettings(this.customViewOwner(apiViewName), this.gridSettingsName(customName), gridSettings,scope);
    }

    get CustomViewName() : string {
        return window.location.hash.includes("es") ? CustomViewDefault : CustomViewCurrent;
    }
}

export const $customViews = new CustomViewsService();
