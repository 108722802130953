import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import { IGridModuleState, IGridState } from '../states/grid-state.interface';

@Module({ dynamic: true, store, name: 'grid' })
class CustomGridModule extends VuexModule implements IGridModuleState {
    [key: string]: any;
    // BaseDataGridPage
    CauseCode: IGridState = { master: null };
    Printer: IGridState = { master: null };
    Stock: IGridState = { master: null };
    Task: IGridState = { master: null };
    TaskSsp: IGridState = { master: null };
    User: IGridState = { master: null };
    // BaseMasterDetailPage
    IngoingOrder: IGridState = { master: null, details: [] };
    IntegrationImports: IGridState = { master: null };
    IntegrationMessages: IGridState = { master: null, details: [] };
    IntegrationRequests: IGridState = { master: null, details: [] };
    IntegrationMonitor: IGridState = { master: null, details: [] };
    Inventory: IGridState = { master: null, details: [] };
    Job: IGridState = { master: null, details: [] };
    Location: IGridState = { master: null, details: [] };
    Maintenance: IGridState = { master: null, details: [] };
    Material: IGridState = { master: null, details: [] };
    MaterialSsp: IGridState = { master: null, details: [] };
    Operation: IGridState = { master: null, details: [] };
    OutgoingOrder: IGridState = { master: null, details: [] };
    Materiales: IGridState = { master: null, details: [] };
    Stockes: IGridState = { master: null, details: [] };
    Taskes: IGridState = { master: null };
    IngoingOrderes: IGridState = { master: null, details: [] };
    Inventoryes: IGridState = { master: null, details: [] };
    Jobes: IGridState = { master: null, details: [] };
    Locationes: IGridState = { master: null, details: [] };
    Maintenancees: IGridState = { master: null, details: [] };
    Operationes: IGridState = { master: null, details: [] };
    OutgoingOrderes: IGridState = { master: null, details: [] };
    Supplieres: IGridState = { master: null, details: [] };
    Draftes: IGridState = { master: null };
    SalesOrderDraftes: IGridState = { master: null };

    @Mutation
    setCustomJobState(dxState: IGridState): void {
        this.Job = dxState;
    }

    @Mutation
    setCustomLocationState(dxState: IGridState): void {
        this.Location = dxState;
    }

    @Mutation
    setCustomMaintenanceState(dxState: IGridState): void {
        this.Maintenance = dxState;
    }

    @Mutation
    setCustomInventoryState(dxState: IGridState): void {
        this.Inventory = dxState;
    }

    @Mutation
    setCustomMaterialState(dxState: IGridState): void {
        this.Material = dxState;
    }

    @Mutation
    setCustomMaterialSspState(dxState: IGridState): void {
        this.MaterialSsp = dxState;
    }

    @Mutation
    setCustomOperationState(dxState: IGridState): void {
        this.Operation = dxState;
    }

    @Mutation
    setCustomIngoingOrderState(dxState: IGridState): void {
        this.IngoingOrder = dxState;
    }

    @Mutation
    setCustomOutgoingOrderState(dxState: IGridState): void {
        this.OutgoingOrder = dxState;
    }

    @Mutation
    setCustomStockState(dxState: IGridState): void {
        this.Stock = dxState;
    }

    @Mutation
    setCustomTaskState(dxState: IGridState): void {
        this.Task = dxState;
    }

    @Mutation
    setCustomTaskSspState(dxState: IGridState): void {
        this.TaskSsp = dxState;
    }

    @Mutation
    setCustomIntegrationImportsState(dxState: IGridState): void {
        this.IntegrationImports = dxState;
    }

    @Mutation
    setCustomIntegrationMessagesState(dxState: IGridState): void {
        this.IntegrationMessages = dxState;
    }

    @Mutation
    setCustomIntegrationRequestsState(dxState: IGridState): void {
        this.IntegrationRequests = dxState;
    }

    @Mutation
    setCustomIntegrationMonitorState(dxState: IGridState): void {
        this.IntegrationMonitor = dxState;
    }

    @Mutation
    setCustomCauseCodeState(dxState: IGridState): void {
        this.CauseCode = dxState;
    }

    @Mutation
    setCustomPrinterState(dxState: IGridState): void {
        this.Printer = dxState;
    }

    @Mutation
    setCustomUserState(dxState: IGridState): void {
        this.User = dxState;
    }

    @Mutation
    setCustomMaterialesState(dxState: IGridState): void {
        this.Materiales = dxState;
    }

    @Mutation
    setCustomStockesState(dxState: IGridState): void {
        this.Stockes = dxState;
    }

    @Mutation
    setCustomJobesState(dxState: IGridState): void {
        this.Jobes = dxState;
    }

    @Mutation
    setCustomLocationesState(dxState: IGridState): void {
        this.Locationes = dxState;
    }

    @Mutation
    setCustomMaintenanceesState(dxState: IGridState): void {
        this.Maintenancees = dxState;
    }

    @Mutation
    setCustomInventoryesState(dxState: IGridState): void {
        this.Inventoryes = dxState;
    }

    @Mutation
    setCustomOperationesState(dxState: IGridState): void {
        this.Operationes = dxState;
    }

    @Mutation
    setCustomIngoingOrderesState(dxState: IGridState): void {
        this.IngoingOrderes = dxState;
    }

    @Mutation
    setCustomOutgoingOrderesState(dxState: IGridState): void {
        this.OutgoingOrderes = dxState;
    }

    @Mutation
    setCustomTaskesState(dxState: IGridState): void {
        this.Taskes = dxState;
    }

    @Mutation
    setCustomSupplieresState(dxState: IGridState): void {
        this.Supplieres = dxState;
    }

    @Mutation
    setCustomDraftesState(dxState: IGridState): void {
        this.Draftes = dxState;
    }

    @Mutation
    setCustomSalesorderdraftesState(dxState: IGridState): void {
        this.Salesorderdraftes = dxState;
    }


    @Mutation
    dispose(): void {
        this.CauseCode = { master: null };
        this.Printer = { master: null };
        this.Stock = { master: null };
        this.Task = { master: null };
        this.User = { master: null };
        
        this.IngoingOrder = { master: null, details: [] };
        this.IntegrationImports = { master: null };
        this.IntegrationMessages = { master: null, details: [] };
        this.IntegrationRequests = { master: null, details: [] };
        this.IntegrationMonitor = { master: null, details: [] };
        this.Inventory = { master: null, details: [] };
        this.Job = { master: null, details: [] };
        this.Location = { master: null, details: [] };
        this.Maintenance = { master: null, details: [] };
        this.Material = { master: null, details: [] };
        this.Operation = { master: null, details: [] };
        this.OutgoingOrder = { master: null, details: [] };
        this.Materiales = { master: null, details: [] };
        this.Stockes = { master: null, details: [] };
        this.Taskes = { master: null};
        this.IngoingOrderes = { master: null, details: [] };
        this.Inventoryes = { master: null, details: [] };
        this.Jobes = { master: null, details: [] };
        this.Locationes = { master: null, details: [] };
        this.Maintenancees = { master: null, details: [] };
        this.Operationes = { master: null, details: [] };
        this.OutgoingOrderes = { master: null, details: [] };
        this.Supplieres = { master: null, details: [] };
        this.Draftes = { master: null};
        this.Salesorderdraftes = { master: null};
    }

    @Action({ rawError: true })
    async setCustomState(payload: { apiViewName: string, dxState: any }) {
        const { apiViewName, dxState } = payload;

        if (this.hasOwnProperty(apiViewName)) {
            const state = JSON.parse(dxState);
            this[`setCustom${apiViewName}State`](state);
        }
        return;
    }
}

export const customGridModule = getModule(CustomGridModule);
