import { IUserChoice, UserChoice } from '@/interfaces/web-api/user-choice.interface';
import { $environment } from '@/services/environment.service';

export function decorateUserChoice(uc: IUserChoice, url: string, headers?: any, body?: any) {
    if (uc.apiData) { return; }
    
    url = url.startsWith('http') ? url : `${$environment.apiURL}${url}`;
    
    const apiData = {
        url,
        headers,
        body
    };

    if (typeof(uc) === 'string') {
        uc = new UserChoice(uc);
    }
    
    Object.defineProperty(uc, 'apiData',  {
        value: apiData,
        writable: false
    });
}
