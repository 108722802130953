export interface IApportJwt {
    enabledFeature: FeatureFlag[];
}

export enum FeatureFlag {
    MaterialsServerSidePaging = "MaterialsServerSidePaging",
    TasksServerSidePaging = "TasksServerSidePaging",
    StockServerSidePaging = "StockServerSidePaging",
    LocationServerSidePaging = "LocationServerSidePaging",
    JobServerSidePaging = "JobServerSidePaging",
    OutgoingOrderServerSidePaging = "OutgoingOrderServerSidePaging",
    IngoingOrderServerSidePaging = "IngoingOrderServerSidePaging",
    OperationServerSidePaging = "OperationServerSidePaging",
    PurchaseModule = "PurchaseModule",
    ReportsServerSidePaging = "ReportsServerSidePaging",
    InventoryServerSidePaging = "InventoryServerSidePaging",
    OpenIdConnect = "OpenIdConnect",
    MaintenanceMinMax = "MaintenanceMinMax",
    MaintenanceOrderBased = "MaintenanceOrderBased",
    InventoryManual = "InventoryManual",
    InventoryZeroCount = "InventoryZeroCount",
    MaterialSummary = "MaterialSummary",
    MaterialTransaction = "MaterialTransaction",
    CreateSalesOrderFromCockpit = "CreateSalesOrderFromCockpit",
    BusinessIntelligence = "BusinessIntelligence",
    SlowMovingMaterialsOverView = "SlowMovingMaterialsOverView"
}
