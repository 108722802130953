export function strRender(str: string, char: string, ...replacers: any[]) {
    if (hasFlatSupport()) {
        replacers = Array.prototype.flat.call(replacers);
    } else {
        replacers = flatten(replacers);
    }

    let res = str;

    replacers.forEach((replacer) => {
        res = res.replace(char, replacer);
    });

    return res;
}

function flatten(arr: any[]): any[] {
    return arr.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
}

function hasFlatSupport() {
    return !!(Array.prototype as any).flat;
}
