











import { Component, Vue } from 'vue-property-decorator';
import ApportKpi from '@/components/kpi/ApportKpi.vue';
import { $kpi } from '@/services/kpi.service';
import { $environment } from '@/services/environment.service';
import { IKpi, IKpiData } from '@/interfaces/web-api/kpi.interfaces';
import { $settingsService } from '@/services/settings.service';
import { mapToUnique } from '@/utilities/map-to-unique.function';
import { dataService } from '@/services/data.service';

@Component({
    components: {
        ApportKpi
    }
})
export default class HomePage extends Vue {
    protected static async asyncData({ route, store }: any) {
        await $environment.whenReady();

        await $settingsService.whenReady();

        await $kpi.fetchData();
    }
    
    private get kpis() {
        const kpis = $kpi.getData();

        return kpis as IKpi[];
    }
    
    get rows() {
        const kpis = this.kpis.filter(kpi => kpi.enabled);

        const groups = mapToUnique<number>(kpis, 'row').sort((a, b) => a - b); 

        return groups.map(group => kpis.filter(kpi => kpi.row === group)
                                            .sort((a, b) => a.order - b.order));
    }
    
    mounted() {
        if (this.kpis && this.kpis.length) {
            this.prepareSignalR();
        }
    }
    
    private prepareSignalR() {
        // note: disabled since BE not ready (no View Level available)
        return;
        // todo: verify viewTypes
        const viewTypes = [
            'OutBoundOrderReportData'
            ];
        
        dataService.initSignalR('ReportDataView', viewTypes , { callback: this.handleSignalR.bind(this) });
    }
    
    private handleSignalR(data: IKpiData[]) {
        try {
            $kpi.update(data);
        } catch (error) {
        }
    }
}
