










import { Component, Prop, Vue } from 'vue-property-decorator';
import DynamicKpi from '@/components/kpi/DynamicKpi.vue';
import { IKpi } from '@/interfaces/web-api/kpi.interfaces';

@Component({
    components: {
        DynamicKpi
    }
})
export default class ApportKpi extends Vue {
    @Prop({ type: Boolean, required: false }) isDraggable!: boolean;
    @Prop({ type: Object, required: true }) data!: IKpi;
    
    get containerClassName() {
        return `a-kpi ${this.isDraggable ? 'a-kpi--draggable' : ''}`.trim();
    }

    startDrag(evt: any, kpi: IKpi) {
        if (!this.isDraggable) { return; }
        
        evt.dataTransfer.dropEffect = 'move';
        evt.dataTransfer.effectAllowed = 'move';
        evt.dataTransfer.setData('itemID', kpi.id);
    }
    
    endDrag(event: any) {
        if (!this.isDraggable || !event.currentTarget.classList.contains('a-kpi--drag-over')) { return; }
        
        event.currentTarget.classList.remove('a-kpi--drag-over');
    }
    
    dragOver(event: any) {
        if (!this.isDraggable || event.currentTarget.classList.contains('a-kpi--drag-over')) {
            return;
        }
        
        event.currentTarget.classList.add('a-kpi--drag-over');
    }
}
