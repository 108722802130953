import { render, staticRenderFns } from "./DashboardPage.vue?vue&type=template&id=bef7e102"
import script from "./DashboardPage.vue?vue&type=script&lang=ts"
export * from "./DashboardPage.vue?vue&type=script&lang=ts"
import style0 from "./DashboardPage.vue?vue&type=style&index=0&id=bef7e102&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "DashboardPage.vue"
export default component.exports