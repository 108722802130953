import { render, staticRenderFns } from "./CustomDialogMenu.vue?vue&type=template&id=1c9437f2&scoped=true"
import script from "./CustomDialogMenu.vue?vue&type=script&lang=ts"
export * from "./CustomDialogMenu.vue?vue&type=script&lang=ts"
import style0 from "./CustomDialogMenu.vue?vue&type=style&index=0&id=1c9437f2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c9437f2",
  null
  
)

component.options.__file = "CustomDialogMenu.vue"
export default component.exports