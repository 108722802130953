const directive = {
    bind: function (el: any, binding:any, vnode: any) {
      el.clickOutsideEvent = function (event: any) {
        // here I check that click was outside the el and his children
        function checkChildNodes(arg: any) {
          let temp1 = []
          if(!Array.isArray(arg)){
            return 0
          }
          arg?.forEach((x: any) => {
            if (typeof x === 'string') {
              let paranntEl = document.querySelector(x)
              if (event.target?.attributes?.class?.value?.includes(x.split('.')?.[1]) ||
              event.target?.parentElement?.attributes?.class?.value?.includes(x.split('.')?.[1])
               || event.target == paranntEl || paranntEl?.contains(event.target))  {
                temp1.push(x)
              }
            } else {
              for (let i in x?.childNodes) {
                if (event.target === x || x?.childNodes[i] === event.target) {
                  temp1.push(i)
                }
              }
  
            }
          })
          return temp1.length
        }
        if (!checkChildNodes(binding.arg) && !(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[binding.expression!](event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el: any) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  };
  export default directive;