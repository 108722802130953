import { IGridSettings } from '@/interfaces/settings.interface';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
import { IGridModuleState, IGridState } from '../states/grid-state.interface';
import StringHelper from '@/utilities/string.helper';

@Module({ dynamic: true, store, name: 'grid' })
class GridModule extends VuexModule implements IGridModuleState {
    [key: string]: any;
    // BaseDataGridPage
    CauseCode: IGridState = { master: null };
    Printer: IGridState = { master: null };
    Stock: IGridState = { master: null };
    Task: IGridState = { master: null };
    TaskSsp: IGridState = { master: null };
    User: IGridState = { master: null };
    // BaseMasterDetailPage
    IngoingOrder: IGridState = { master: null, details: [] };
    IntegrationImports: IGridState = { master: null };
    IntegrationMessages: IGridState = { master: null, details: [] };
    IntegrationRequests: IGridState = { master: null, details: [] };
    IntegrationMonitor: IGridState = { master: null, details: [] };
    Inventory: IGridState = { master: null, details: [] };
    Job: IGridState = { master: null, details: [] };
    Location: IGridState = { master: null, details: [] };
    Maintenance: IGridState = { master: null, details: [] };
    Material: IGridState = { master: null, details: [] };
    MaterialSsp: IGridState = { master: null, details: [] };
    Operation: IGridState = { master: null, details: [] };
    OutgoingOrder: IGridState = { master: null, details: [] };
    Materiales: IGridState = { master: null, details: [] };
    Stockes: IGridState = { master: null, details: [] };
    Taskes: IGridState = { master: null };
    IngoingOrderes: IGridState = { master: null, details: [] };
    Inventoryes: IGridState = { master: null, details: [] };
    Jobes: IGridState = { master: null, details: [] };
    Locationes: IGridState = { master: null, details: [] };
    Maintenancees: IGridState = { master: null, details: [] };
    Operationes: IGridState = { master: null, details: [] };
    OutgoingOrderes: IGridState = { master: null, details: [] };
    Supplieres: IGridState = { master: null, details: [] };
    Draftes: IGridState = { master: null };
    SalesOrderDraftes: IGridState = { master: null };

    @Mutation
    setJobState(dxState: IGridState): void {
        this.Job = dxState;
    }

    @Mutation
    setLocationState(dxState: IGridState): void {
        this.Location = dxState;
    }

    @Mutation
    setMaintenanceState(dxState: IGridState): void {
        this.Maintenance = dxState;
    }

    @Mutation
    setInventoryState(dxState: IGridState): void {
        this.Inventory = dxState;
    }

    @Mutation
    setMaterialState(dxState: IGridState): void {
        this.Material = dxState;
    }

    @Mutation
    setMaterialSspState(dxState: IGridState): void {
        this.MaterialSsp = dxState;
    }

    @Mutation
    setOperationState(dxState: IGridState): void {
        this.Operation = dxState;
    }

    @Mutation
    setIngoingOrderState(dxState: IGridState): void {
        this.IngoingOrder = dxState;
    }

    @Mutation
    setOutgoingOrderState(dxState: IGridState): void {
        this.OutgoingOrder = dxState;
    }

    @Mutation
    setStockState(dxState: IGridState): void {
        this.Stock = dxState;
    }

    @Mutation
    setTaskState(dxState: IGridState): void {
        this.Task = dxState;
    }

    @Mutation
    setTaskSspState(dxState: IGridState): void {
        this.TaskSsp = dxState;
    }

    @Mutation
    setIntegrationImportsState(dxState: IGridState): void {
        this.IntegrationImports = dxState;
    }

    @Mutation
    setIntegrationMessagesState(dxState: IGridState): void {
        this.IntegrationMessages = dxState;
    }

    @Mutation
    setIntegrationRequestsState(dxState: IGridState): void {
        this.IntegrationRequests = dxState;
    }

    @Mutation
    setIntegrationMonitorState(dxState: IGridState): void {
        this.IntegrationMonitor = dxState;
    }

    @Mutation
    setCauseCodeState(dxState: IGridState): void {
        this.CauseCode = dxState;
    }

    @Mutation
    setPrinterState(dxState: IGridState): void {
        this.Printer = dxState;
    }

    @Mutation
    setUserState(dxState: IGridState): void {
        this.User = dxState;
    }

    @Mutation
    setMaterialesState(dxState: IGridState): void {
        this.Materiales = dxState;
    }

    @Mutation
    setStockesState(dxState: IGridState): void {
        this.Stockes = dxState;
    }

    @Mutation
    setJobesState(dxState: IGridState): void {
        this.Jobes = dxState;
    }

    @Mutation
    setLocationesState(dxState: IGridState): void {
        this.Locationes = dxState;
    }

    @Mutation
    setMaintenanceesState(dxState: IGridState): void {
        this.Maintenancees = dxState;
    }

    @Mutation
    setInventoryesState(dxState: IGridState): void {
        this.Inventoryes = dxState;
    }

    @Mutation
    setOperationesState(dxState: IGridState): void {
        this.Operationes = dxState;
    }

    @Mutation
    setIngoingOrderesState(dxState: IGridState): void {
        this.IngoingOrderes = dxState;
    }

    @Mutation
    setOutgoingOrderesState(dxState: IGridState): void {
        this.OutgoingOrderes = dxState;
    }

    @Mutation
    setTaskesState(dxState: IGridState): void {
        this.Taskes = dxState;
    }

    @Mutation
    setSupplieresState(dxState: IGridState): void {
        this.Supplieres = dxState;
    }

    @Mutation
    setDraftesState(dxState: IGridState): void {
        this.Draftes = dxState;
    }

    @Mutation
    setSalesOrderDraftesState(dxState: IGridState): void {
        this.SalesOrderDraftes = dxState;
    }


    @Mutation
    dispose(): void {
        this.CauseCode = { master: null };
        this.Printer = { master: null };
        this.Stock = { master: null };
        this.Task = { master: null };
        this.User = { master: null };
        
        this.IngoingOrder = { master: null, details: [] };
        this.IntegrationImports = { master: null };
        this.IntegrationMessages = { master: null, details: [] };
        this.IntegrationRequests = { master: null, details: [] };
        this.IntegrationMonitor = { master: null, details: [] };
        this.Inventory = { master: null, details: [] };
        this.Job = { master: null, details: [] };
        this.Location = { master: null, details: [] };
        this.Maintenance = { master: null, details: [] };
        this.Material = { master: null, details: [] };
        this.Operation = { master: null, details: [] };
        this.OutgoingOrder = { master: null, details: [] };
        this.Materiales = { master: null, details: [] };
        this.Stockes = { master: null, details: [] };
        this.Taskes = { master: null};
        this.IngoingOrderes = { master: null, details: [] };
        this.Inventoryes = { master: null, details: [] };
        this.Jobes = { master: null, details: [] };
        this.Locationes = { master: null, details: [] };
        this.Maintenancees = { master: null, details: [] };
        this.Operationes = { master: null, details: [] };
        this.OutgoingOrderes = { master: null, details: [] };
        this.Supplieres = { master: null, details: [] };
        this.Draftes = { master: null};
        this.SalesOrderDraftes = { master: null};
    }

    @Action
    async setState(payload: { apiViewName: string, dxState: IGridState }) {
        const { apiViewName, dxState } = payload;

        if (this.hasOwnProperty(apiViewName)) {
            this[`set${apiViewName}State`](dxState);
        }
        return;
    }

    @Action
    async setSettings(settings: IGridSettings) {
        if (!settings) { return; }
        
        for (const key in settings) {
            if (settings.hasOwnProperty(key)) {
                const stateStr = settings[key];

                if (stateStr) {
                    try {
                        const state = JSON.parse(stateStr);
                        const getterKey = StringHelper.startWithUpperCase(key);
                        const setterKey = `set${getterKey}State`;
                        
                        if (this.hasOwnProperty(setterKey)) {
                            this[setterKey](state);
                        }
                    } catch (error) {}
                }
            }
        }
    }
}

export const gridModule = getModule(GridModule);
