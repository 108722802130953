import Vue from 'vue';

import StringHelper from '@/utilities/string.helper';
import { dxTranslations, staticTranslations } from '@/i18n/index';
import { i18NModule } from '@/store/modules/i18n.module';
import { isJSON } from '@/utilities/is-json.function';
import { IKeyValueObject, ILanguageChange } from '../interfaces/common.interface';
import { strRender } from '../utilities/str-render.function';
import { $environment } from './environment.service';
import { $settingsService } from './settings.service';
import { $webApi } from './web-api.service';
import ObjectHelper from '@/utilities/object.helper';
import { isDevelopment } from '@/utilities/is-development.function';
import { locale, loadMessages } from 'devextreme/localization';

export interface ICustomI18NPlugin {
    translate: (key: string, ...replacers: any[]) => void;

    setLang: (langCode: string) => void;

    fetchLang: (langCode: string) => Promise<void>;

    getLanguages: () => any;

    readonly isReady: boolean;
}

class I18N implements ICustomI18NPlugin {
    private pendingPromise: any;
    private isPending: boolean = false;
    private fetched: Set<string> = new Set();

    private get currentLang(): any {
        const { translation } = i18NModule;

        return translation;
    }

    private get replacerSign(): string {
        return '%s';
    }

    constructor() {
        Vue.prototype.$T = this.translate.bind(this);
    }

    dispose() {
        this.pendingPromise = null;
        this.isPending = false;
        this.fetched = new Set();
    }

    get isReady(): boolean {
        return this.isPending === false && this.currentLang !== null;
    }

    translate(key: string, ...replacers: any[]): IKeyValueObject<string> {
        const obj = { key, value: '' };
        
        if (this.isPending) {
            this.getValueWhenReady(obj, key, replacers);
        } else {
            obj.value = this.getValue(key, replacers);
        }

        return obj;
    }

    async setLang(langCode: string): Promise<void> {
        const { currentLanguage } = $settingsService;

        if (currentLanguage === langCode || !langCode) {
            return;
        }

        const hasLang = await i18NModule.has(langCode);

        if (!hasLang) {
            await this.getTranslationFor(langCode);
        }

        i18NModule.changeLang(langCode);

        const dx = this.getDxTranslationFor(langCode);
        if (dx) {
            loadMessages(dx);
            locale(this.toShortLangCode(langCode));
        }

    }

    async fetchLang(langCode: string): Promise<void> {
        if (this.fetched.has(langCode)) {
            return;
        }

        await this.getTranslationFor(langCode);
    }

    getLanguages(): ILanguageChange[] {
        const language = [
            {name: 'Danish', language: 'da-DK'},
            {name: 'English', language: 'en-GB'},
            {name: 'Norwegian', language: 'nb-NO'} 
        ]
        
        return language;
    }

    private async getValueWhenReady(obj: IKeyValueObject<string>, key: string, replacers: any[]): Promise<void> {
        await this.waitIfPending();

        obj.value = this.getValue(key, replacers);
    }

    private getValue(key: string, replacers: any[]): string {
        let value: string = this.getTranslationValue(key);

        if (!value) {
            value = this.isValidKey(key) ? `#${key}` : key;
            if (isDevelopment()) {
                console.log(`Development-only warning: Missing translation for "${key}"`);
            }
        } else if (value.includes(this.replacerSign)) {
            value = strRender(value, this.replacerSign, ...replacers);
        }

        return value;
    }

    private waitIfPending(): any {
        if (this.isPending) {
            return this.pendingPromise;
        }

        return Promise.resolve();
    }

    private tryToGetStatic(langCode: string): any {
        try {
            return staticTranslations[langCode] || {};
        } catch (error) {
            return {};
        }
    }

    private getDxTranslationFor(langCode: string): any {
        try {
            return dxTranslations[langCode] || {};
        } catch (error) {
            return dxTranslations['en-GB'] || {}
        }
    }

    private async getTranslationFor(langCode: string): Promise<void> {
        const staticT = this.tryToGetStatic(langCode);
        let dynamicT = await this.fetchTranslation(langCode);
        if(dynamicT) {
            dynamicT = [];
        }
        const lang = ObjectHelper.deepAssign(staticT, dynamicT);

        i18NModule.setLang({ langCode, lang });
    }

    private async tryToFetch(langCode: string, resolve: any): Promise<any> {
        let result;
        this.isPending = true;

        try {
            const version = $environment.version || '1.0';
            result = await $webApi.GET.Localization.Translations(version, langCode);

            if (result && isJSON(result)) {
                result = JSON.parse(result);
            }

            this.fetched.add(langCode);
        } catch (error) {
            result = {};
        }

        this.isPending = false;

        return resolve(result);
    }

    private fetchTranslation(langCode: string): Promise<any> {
        if (this.isPending) { return this.pendingPromise; }
        const promise = new Promise<any>((resolve, reject) => {
            this.tryToFetch(langCode, resolve);
        });

        this.pendingPromise = promise;

        return promise;
    }

    private getTranslationValue(fullKey: string): string {
        let value = '';
        let dictionary = { ...this.currentLang };
        const keys = this.getKeys(fullKey);

        keys.forEach(key => {
            const upperCaseKey = StringHelper.startWithUpperCase(key);
            const lowerCaseKey = StringHelper.startWithLowerCase(key);

            if (dictionary && (dictionary.hasOwnProperty(upperCaseKey) || dictionary.hasOwnProperty(lowerCaseKey))) {
                const val = dictionary[upperCaseKey] || dictionary[lowerCaseKey];

                if (typeof (val) === 'string') {
                    value = val;
                } else {
                    dictionary = val;
                }
            }
        });

        return value;
    }

    private getKeys(fullKey: string): string[] {
        return fullKey.split('.');
    }

    private isValidKey(fullKey: string): boolean {
        return !!fullKey && fullKey.includes('.');
    }

    private toShortLangCode(langCode: string): string {
        return langCode.substring(0, 2);
    } 

    get languageCode(): string {
        return i18NModule.langCode;
    }
}

export const $i18N = new I18N();
