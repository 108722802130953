import { IDxFilter, IFilterEvent } from '@/interfaces/dx-filter.interface';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { GUID } from '../../utilities/guid.extension';
import store from '../index';
import { IFilterState, IPageState } from '../states/filter-state.interface';

@Module({ dynamic: true, store, name: 'filter' })
class FilterModule extends VuexModule implements IFilterState {
    [key: string]: any;
    pages: IPageState = {
        Job: [],
        Location: [],
        Maintenance: [],
        Inventory: [],
        IntegrationMonitor: [],
        Material: [],
        MaterialSsp: [],
        Materiales: [],
        Operation: [],
        IngoingOrder: [],
        OutgoingOrder: [],
        Stock: [],
        Task: [],
        Draft:[],
        IntegrationImports: [],
        IntegrationMessages: [],
        IntegrationRequests: [],
        Taskes: [],
        Stockes: [],
        Locationes: [],
        Jobes: [],
        Draftes: [],
        Supplieres: [],
        IngoingOrderes: [],
        OutgoingOrderes: [],
        Operationes: [],
        TaskSsp: [],
        Maintenancees:[],
        Inventoryes:[],
        SalesOrderDraftes:[],
        Sales:[],
    };
    event: IFilterEvent | null = null;
    draftNo: string | null = null;
    supplierIds: string[] | null = null;
    genericFilter: any | null = null;
    searchText: string = '';


    get getSearchText(): string {
        return this.searchText;
    }

    get getAll(): IPageState {
        return this.pages;
    }

    get getJob(): IDxFilter[] {
        return this.pages.Job;
    }

    get getLocation(): IDxFilter[] {
        return this.pages.Location;
    }

    get getMaintenance(): IDxFilter[] {
        return this.pages.Maintenance;
    }

    get getInventory(): IDxFilter[] {
        return this.pages.Inventory;
    }

    get getMaterial(): IDxFilter[] {
        return this.pages.Material;
    }

    get getMaterialSsp(): IDxFilter[] {
        return this.pages.MaterialSsp;
    }

    get getMateriales(): IDxFilter[] {
        return this.pages.Materiales;
    }

    get getOperation(): IDxFilter[] {
        return this.pages.Operation;
    }

    get getIngoingOrder(): IDxFilter[] {
        return this.pages.IngoingOrder;
    }
    
    // note: obsolete, remove when not needed
    get getIntegrationMonitor(): IDxFilter[] {
        return this.pages.IntegrationMonitor;
    }

    get getIntegrationImports(): IDxFilter[] {
        return this.pages.IntegrationImports;
    }

    get getIntegrationMessages(): IDxFilter[] {
        return this.pages.IntegrationMessages;
    }

    get getIntegrationRequests(): IDxFilter[] {
        return this.pages.IntegrationRequests;
    }

    get getOutgoingOrder(): IDxFilter[] {
        return this.pages.OutgoingOrder;
    }

    get getStock(): IDxFilter[] {
        return this.pages.Stock;
    }

    get getTask(): IDxFilter[] {
        return this.pages.Task;
    }

    get getTaskSsp(): IDxFilter[] {
        return this.pages.TaskSsp;
    }

    get getEvent(): IFilterEvent | null {
        return this.event;
    }

    get getDraftNo(): string | null {
        return this.draftNo;
    }

    get getDraft(): IDxFilter[] {
        return this.pages.Draft;
    }

    get getDraftSearchCriteriaSupplierIds(): string[] | null {
        return this.supplierIds;
    }

    get getTaskes(): IDxFilter[] {
        return this.pages.Taskes;
    }

    get getStockes(): IDxFilter[] {
        return this.pages.Stockes;
    }

    get getLocationes(): IDxFilter[] {
        return this.pages.Locationes;
    }

    get getJobes(): IDxFilter[] {
        return this.pages.Jobes;
    }

    get getDraftes(): IDxFilter[] {
        return this.pages.Draftes;
    }

    get getSupplieres(): IDxFilter[] {
        return this.pages.Supplieres;
    }

    get getOutgoingOrderes(): IDxFilter[] {
        return this.pages.OutgoingOrderes;
    }

    get getIngoingOrderes(): IDxFilter[] {
        return this.pages.IngoingOrderes;
    }

    get getOperationes(): IDxFilter[] {
        return this.pages.Operationes;
    }

    get getMaintenancees(): IDxFilter[] {
        return this.pages.Maintenancees;
    }

    get getInventoryes(): IDxFilter[] {
        return this.pages.Inventoryes;
    }

    get getGenericFilter(): any {
        return this.genericFilter;
    }

    get getSalesOrderDraftes(): any {
        return this.pages.SalesOrderDraftes;
    }

    get getSales(): any {
        return this.pages.Sales;
    }

    @Mutation
    setJob(filters: IDxFilter[]): void {
        this.pages.Job = filters;
    }

    @Mutation
    setLocation(filters: IDxFilter[]): void {
        this.pages.Location = filters;
    }

    @Mutation
    setMaintenance(filters: IDxFilter[]): void {
        this.pages.Maintenance = filters;
    }

    @Mutation
    setInventory(filters: IDxFilter[]): void {
        this.pages.Inventory = filters;
    }

    @Mutation
    setMaterial(filters: IDxFilter[]): void {
        this.pages.Material = filters;
    }

    @Mutation
    setMaterialSsp(filters: IDxFilter[]): void {
        this.pages.MaterialSsp = filters;
    }

    @Mutation
    setMateriales(filters: IDxFilter[]): void {
        this.pages.Materiales = filters;
    }

    @Mutation
    setOperation(filters: IDxFilter[]): void {
        this.pages.Operation = filters;
    }

    @Mutation
    setIngoingOrder(filters: IDxFilter[]): void {
        this.pages.IngoingOrder = filters;
    }
    
    @Mutation
    setIntegrationMonitor(filters: IDxFilter[]): void {
        this.pages.IntegrationMonitor = filters;
    }

    @Mutation
    setIntegrationImports(filters: IDxFilter[]): void {
        this.pages.IntegrationImports = filters;
    }

    @Mutation
    setIntegrationMessages(filters: IDxFilter[]): void {
        this.pages.IntegrationMessages = filters;
    }
    
    @Mutation
    setIntegrationRequests(filters: IDxFilter[]): void {
        this.pages.IntegrationRequests = filters;
    }

    @Mutation
    setOutgoingOrder(filters: IDxFilter[]): void {
        this.pages.OutgoingOrder = filters;
    }

    @Mutation
    setStock(filters: IDxFilter[]): void {
        this.pages.Stock = filters;
    }

    @Mutation
    setTask(filters: IDxFilter[]): void {
        this.pages.Task = filters;
    }

    @Mutation
    setEvent(event: IFilterEvent | null): void {
        this.event = event;
    }

    @Mutation
    setDraft(filters: IDxFilter[]): void {
        this.pages.Draft = filters;
    }

    @Mutation
    setDraftNo(filters: string): void {
        this.draftNo = filters;
    }

    @Mutation
    setDraftSearchCriteriaDateFilter(filters: IDxFilter[]): void {
        this.pages.Draft = filters;
    }


    @Mutation
    setDraftSearchCriteriaSupplierIds(filters: string[] | null): void {
        this.supplierIds = filters;
    }

    @Mutation
    setLocationes(filters: IDxFilter[]): void {
        this.pages.Locationes = filters;
    }

    @Mutation
    setJobes(filters: IDxFilter[]): void {
        this.pages.Jobes = filters;
    }

    @Mutation
    setDraftes(filters: IDxFilter[]): void {
        this.pages.Draftes = filters;
    }

    @Mutation
    setSupplieres(filters: IDxFilter[]): void {
        this.pages.Supplieres = filters;
    }

    @Mutation
    setOutgoingOrderes(filters: IDxFilter[]): void {
        this.pages.OutgoingOrderes = filters;
    }

    @Mutation
    setIngoingOrderes(filters: IDxFilter[]): void {
        this.pages.IngoingOrderes = filters;
    }

    @Mutation
    setOperationes(filters: IDxFilter[]): void {
        this.pages.Operationes = filters;
    }

    @Mutation
    setSalesOrderDraftes(filters: IDxFilter[]): void {
        this.pages.SalesOrderDraftes = filters;
    }
    
    @Mutation
    updateFilterLabel(payload: {viewName: string, name: string, value: any}): void {
        const {viewName, name, value} = payload;
        
        if (!this.pages.hasOwnProperty(viewName)) {
            return;
        }
        
        const filters = this.pages[viewName];
        const filter = filters.find(f => f.name === name);

        if (!filter) {
            return;
        }    
        
        filter.label = value;
        
        this.pages[viewName] = filters;
    }
    
    @Mutation
    updateFilterTitle(payload: {viewName: string, name: string, value: any}): void {
        const {viewName, name, value} = payload;
        
        if (!this.pages.hasOwnProperty(viewName)) {
            return;
        }
        
        const filters = this.pages[viewName];
        const filter = filters.find(f => f.name === name);
        
        if (!filter) {
            return;
        }    
        
        filter.title = value;
        
        this.pages[viewName] = filters;
    }
    
    @Mutation
    updateFilterValueSync(payload: { viewName: string, name: string, value: any }): void {
        
        const {viewName, name, value} = payload;
        
        if (!this.pages.hasOwnProperty(viewName)) {
            return;
        }
        
        const filters = this.pages[viewName];
        const filter = filters.find(f => f.name === name);

        if (!filter) {
            return;
        }   

        if (Array.isArray(value)) {
            if (Array.isArray(filter.value)) {
                filter.value.splice(0);
            } else {
                filter.value = [];
            }
            (filter.value as any[]).push(...value);
        } else {
            filter.value = value;
        }
        
        this.pages[viewName] = filters;
    }

    @Mutation
    setTaskes(filters: IDxFilter[]): void {
        this.pages.Taskes = filters;
    }

    @Mutation
    setStockes(filters: IDxFilter[]): void {
        this.pages.Stockes = filters;
    }

    @Mutation
    setTaskSsp(filters: IDxFilter[]): void {
        this.pages.TaskSsp = filters;
    }

    @Mutation
    setInventoryes(filters: IDxFilter[]): void {
        this.pages.Inventoryes = filters;
    }

    @Mutation
    setMaintenancees(filters: IDxFilter[]): void {
        this.pages.Maintenancees = filters;
    }

    @Mutation
    setGenericFilter(event: any | null): void {
        this.genericFilter = event;
    }

    @Mutation
    setSales(event: any | null): void {
        this.pages.Sales = event;
        this.pages.SalesOrderDraftes = event;
    }

    @Mutation
    setSearchText(searchText: string): void {
        this.searchText = searchText;
    }

    @Action
    async setPageFilter(payload: { viewName: string, filters: IDxFilter[] }): Promise<void> {
        const { viewName, filters } = payload;

        if (this.pages.hasOwnProperty(viewName)) {
            this[`set${viewName}`](filters);
        }
        
        return;
    }
    
    @Action
    async updatePageFilterValue(payload: {viewName: string, name: string, value: any}): Promise<void> {
        const {viewName, name, value } = payload;

        if (!this.pages.hasOwnProperty(viewName)) {
            return;
        }

        const filters = [...this[`get${viewName}`]];
        const filter = filters.find(f => f.name === name);

        if (!filter) {
            return;
        }    
        
        if (Array.isArray(value)) {
            if (Array.isArray(filter.value)) {
                filter.value.splice(0);
            } else {
                filter.value = [];
            }
            (filter.value as any[]).push(...value);
        } else {
            filter.value = value;
        }

        this[`set${viewName}`](filters);
        
        return;
    }

    @Action
    async dispose(): Promise<void> {
        for (const page in this.pages) {
            if (this.hasOwnProperty(page)) {
                this[`set${page}`]([]);
            }
        }

        this.setEvent(null);
        
        return;
    }
}

export const filterModule = getModule(FilterModule);
