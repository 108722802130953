export default class DOMHelper {
    static $canvas: HTMLCanvasElement | null = null;

    static getCssStyle(element: Element, propertyName: string) {
        return window.getComputedStyle(element, null).getPropertyValue(propertyName);
    }

    static calcTextWidth(text: string, element: Element) {
        const fontWeight = DOMHelper.getCssStyle(element, 'font-weight') || 'normal';
        const fontSize = DOMHelper.getCssStyle(element, 'font-size') || '12px';
        const fontFamily = DOMHelper.getCssStyle(element, 'font-family') || 'Azo Sans';

        DOMHelper.$canvas = DOMHelper.$canvas || document.createElement('canvas');
        const context = DOMHelper.$canvas.getContext('2d') as CanvasRenderingContext2D;
        context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
        const metrics = context.measureText(text);

        return metrics.width;
    }

    static isChildOf(element: HTMLElement | null, selector: string): boolean {
        if (!selector || typeof (selector) !== 'string') {
            console.log(`Failed check isChildOf: invalid selector "${selector}"!`);
            return false;
        }
        if (!element) {
            console.log(`Failed check isChildOf: empty element!`);
            return false;
        }

        const classSelector = selector.startsWith('.') ? selector.substring(1) : null;
        const idSelector = selector.startsWith('#') ? selector.substring(1) : null;

        while (element) {
            if (idSelector && element.id === idSelector) {
                return true;
            }

            if (classSelector && element.classList.contains(classSelector)) {
                return true;
            }

            element = element.parentElement;
        }
        return false;
    }
}
