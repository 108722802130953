export class LayoutWorkerService {
    private layout = this.init();
    
    onResize(width: number, height: number) {
        this.layout.width = width;
        this.layout.height = height;
    }
    
    private init(): ILayout {
        return {
            width: 0,
            height: 0
        };
    }
}

interface ILayout {
    width: number;
    height: number;
}
