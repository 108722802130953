














import DOMHelper from '@/utilities/dom.helper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface IState {
    id: string;
    isOpened: boolean;
    activeClass: string;
    showArrow: boolean;
    clickHandlerRef: any;
}

@Component({})
export default class CustomDialogMenu extends Vue {
    @Prop({ type: Array }) closeOnClasses!: string[];
    @Prop({ type: Boolean }) showArrow!: boolean;
    @Prop({ type: Boolean }) usePage!: boolean;

    @Prop({ type: Function, required: false}) closeOn: any;
    
    @Watch('showArrow') onChange(newVal?: boolean, oldVal?: boolean) {
        this.State.showArrow = newVal || false;
    }

    @Watch('State.isOpened') onVisibilityChange(isOpen: boolean) {
        if (isOpen) {
            this.addClickListener();
        } else {
            this.removeClickListener();
        }
    }
    
    State: IState = {
        id: this.generateRandomId(),
        isOpened: false,
        activeClass: '',
        showArrow: false,
        clickHandlerRef: null
    };

    mounted() {
        this.$el.id = this.State.id;   

        this.State.showArrow = this.showArrow;
    }

    onClick(event: MouseEvent) {
        if (!this.closeOnClasses || !this.closeOnClasses.length) { return; }
        
        const htmlElement = event.target as HTMLElement;

        const closeModal = this.closeOnClasses.some(selector => this.hasClass(selector, htmlElement));

        if (closeModal) {
            this.close();
        }
    }

    onToggle() {
        if (this.State.isOpened) {
            this.close();
        } else {
            this.open();
        }
    }
    
    private hasClass(closeClass: string, { className, parentElement }: HTMLElement): boolean {
        if (typeof(className) === 'string' && className.includes(closeClass)) {
            return true;
        }
        
        if (parentElement) {
            return this.hasClass(closeClass, parentElement);
        }
        
        return false;
    }

    private addClickListener() {
        setTimeout(() => {
            this.State.clickHandlerRef = this.clickHandler.bind(this);
            window.addEventListener('click', this.State.clickHandlerRef);
        }, 50);
    }
    private removeClickListener() {
        if (this.State.clickHandlerRef) {
            window.removeEventListener('click', this.State.clickHandlerRef);
            this.State.clickHandlerRef = null;
        }
    }

    private clickHandler(event: MouseEvent) {
        const elem = event.target as HTMLElement;

        const closeModal = DOMHelper.isChildOf(elem, '.dialog-menu') ?
            !DOMHelper.isChildOf(elem, `#${this.State.id}`) :
            true;     
            
        if (closeModal) {
            this.close();
        }
    }

    private generateRandomId(): string {
        return `c-modal-${Math.floor(Math.random() * 100000)}`;
    }

    private close() {
        this.State.isOpened = false;
        this.State.activeClass = '';
    }

    private open() {
        this.State.isOpened = true;
        this.State.activeClass = 'active';
    }
}

