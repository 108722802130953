import { userModule } from '@/store/modules/user.module';
import { IUser, IUserRole } from '@/interfaces/web-api/user.interface';
import { $system } from './system.service';
import { IAuthResponse } from '@/interfaces/web-api/login.interface';
import { isDevelopment } from '@/utilities/is-development.function';

class UserService {
    get isAdmin(): boolean {
        return userModule.getIsAdmin;
    }
    get isAuth(): boolean {
        return userModule.getIsAuth;
    }
    
    get displayName(): string {
        return userModule.displayName;
    }
    get roles(): IUserRole[] {
        return userModule.getRoles;
    }
    get defaultLanguage(): string | null {
        const { user } = userModule;

        if (user) {
            return user.defaultLanguage;
        }

        return null;
    }

    get getUser(): IUser | null {
        return userModule.getUser;
    }

    get UserRoleName() {
        return userModule.UserRoleName;
    }

    async login(value: IAuthResponse): Promise<void> {
        const { userRoles = [], userName } = value;

        if (this.isAdminDevMode(userName) && !userRoles.some(r => r.userRoleName === 'GlobalAdminRole')) {
            // note: when debugging as "admin" always make sure to have "GlobalAdminRole"
            userRoles.push({
                userRoleName: 'GlobalAdminRole',
                deleted: false,
                id: '0',
                loadCarriers: [],
                wareHouseAreas: [],
                assignedUser: 0
            });
        }

        return userModule.login(value);
    }
    
    async logout(): Promise<void> {
        $system.stopTracking();
        await userModule.logout();
    }

    private isAdminDevMode(userName: string): boolean {
        return isDevelopment() && userName.toLowerCase() === 'admin';
    }
}

export const $user = new UserService();
