import { IKeyValue } from '../interfaces/common.interface';

export function mapToUnique<T>(list: any[], ...propertyKeys: string[]) {
    let result: T[] = [];

    result = list.map(item => {
        if (propertyKeys.length === 0) {
            return item;
        }
        
        let simpleValue: T | null = null;
        const objectValue: IKeyValue<any> = {};
        propertyKeys.forEach(key => {
            const value = (!!item && item.hasOwnProperty(key)) ? item[key] : null;
            if (propertyKeys.length === 1) {
                simpleValue = value;
            } else {
                objectValue[key] = value;
            }
        });

        return simpleValue || objectValue;
    });

    result = [...new Set(result)];

    return result;
}
