import { IKpiData, IKpi, IOutgoingOrderKpi, IOrderKpi, ILocationKpi, ILocationRoleKpi } from '@/interfaces/web-api/kpi.interfaces';
import { kpiModule } from '@/store/modules/kpi.module';
import { $webApi } from './web-api.service';

class KpiService {
    async fetchData(): Promise<void> {
        const response = await $webApi.GET.Report.All();

        this.mergeData(response);
    }

    getData() {
        return kpiModule.kpis;
    }
    
    update(kpis: IKpiData[]) {
        this.mergeData(kpis);
    }
    
    toDisplayObject(object: any): Array<{ label: string, value: null }> {
        const objArray = [];
        
        for (const key in object) {
            const objVal = {
                label: '',
                value: null
            };

            if (object.hasOwnProperty(key)) {
                objVal.value = object[key] || 0;
                objVal.label = key;
            }

            objArray.push(objVal);
        }

        return objArray;
    }

    private mergeData(data: IKpiData[] = []): void {
        const { kpis } = kpiModule;

        const merged = kpis.map((setting, index) => {
            const { name } = setting;
            const kpiData = data.find(d => d.name === name);

            if (!kpiData) {
                return { ...setting, enabled: false };
            }

            return {
                ...setting,
                value: (!kpiData || !setting.enabled) ? null : this.parseData(kpiData.reportDataJson),
                id: !kpiData ? index : kpiData.id
            };
        });
        
        kpiModule.setKpis(merged as IKpi[]);
    }
    
    private parseData(data: any): any {
        const { name } = data;
        
        const kpiName = name;

        switch (kpiName) {
            case 'OutBoundOrderReportData':
                return new OutgoingOrderKpiData(data);
            case 'InBoundOrderReportData':
                return new IngoingOrderKpiData(data);
            case 'PickingTaskPerLocationRoleReportData':
                return new LocationKpiData(data);
            default:
                return null;
        }
    }
}

export const $kpi = new KpiService();

export interface IKpiValue {}

export class IngoingOrderKpiData implements IKpiValue {
    readonly main = {
        initial: 0,
        started: 0,
        completed: 0,
        total: 0
    };
    readonly orderLines: any;
    
    constructor(data: IOrderKpi) {
        const { completed, initial, started, orderLines } = data;

        this.main = {
            initial,
            started, 
            completed,
            total: initial + started + completed
        };
        this.orderLines = orderLines;
    }
}

export class OutgoingOrderKpiData extends IngoingOrderKpiData {
    readonly tasks: any;
    
    constructor(data: IOutgoingOrderKpi) {
        super(data);
        
        const { tasks } = data;
        this.tasks = tasks;
    }
}

export class LocationKpiData {
    readonly locationRoles: ILocationRoleKpi[];
    
    constructor(data: ILocationKpi) {
        const { locationRoles } = data;
        
        this.locationRoles = locationRoles;
    }
}

