import { DxSelectorType, IDxFilter, IFilterEvent } from '@/interfaces/dx-filter.interface';
import { filterModule } from '@/store/modules/filter.module';
import { IPageState } from '@/store/states/filter-state.interface';
import DateHelper from '@/utilities/date.helper';
import moment from 'moment';

class DxFilterService {
    
    private getPageFilter(viewName: string): IDxFilter[] {
        if (filterModule.pages.hasOwnProperty(viewName)) {
            const filters = filterModule[`get${viewName}`];

            return Array.isArray(filters) ? filters : [filters];
        }

        return [];
    }

    queryString(apiViewName: string, useHiddenFilters: boolean = true): string {
        const filters = this.getPageFilter(apiViewName);

        const isEsPage = window.location.hash.includes("es-page");
        if(isEsPage) {
           return this.getTextValueEs(filters);
        }

        const queryString = this.toQueryString(filters, useHiddenFilters);
        
        return queryString;
    }

    prepareDraftQuery(apiViewName: string, useHiddenFilters: boolean = true): string {
        const filters = this.getPageFilter(apiViewName);

        const queryString = this.toQueryString(filters, useHiddenFilters);

        return queryString;
    }

    get(apiViewName: string): IDxFilter[] {
        const viewName = this.extractViewName(apiViewName);

        return this.getPageFilter(viewName);
    }

    getAll(): IPageState {
        return filterModule.getAll;
    }

    getValueOf(viewName: string, name: string): any {
        const filters = this.getPageFilter(viewName);
        if (!filters) { return; }

        const filter = filters.find((f: any) => f.name === name);

        if (!filter) {
            return null;
        }

        return filter.value;
    }

    async set(apiViewName: string, filters: IDxFilter[]): Promise<void> {
        const viewName = this.extractViewName(apiViewName);
        return filterModule.setPageFilter({ viewName, filters });
    }

    updateLabel(viewName: string, name: string, label: any): void {
        return filterModule.updateFilterLabel({ viewName, name, value: label });
    }

    updateTitle(viewName: string, name: string, title: any): void {
        return filterModule.updateFilterTitle({ viewName, name, value: title });
    }

    updateValueSync(viewName: string, name: string, value: any): void {
        return filterModule.updateFilterValueSync({ viewName, name, value });
    }

    async updateValue(viewName: string, name: string, value: any): Promise<void> {
        return filterModule.updatePageFilterValue({ viewName, name, value });
    }

    event(): IFilterEvent | null {
        return filterModule.getEvent;
    }

    notify(event: IFilterEvent): void {
        return filterModule.setEvent(event);
    }

    isMatch(obj: any, filters: IDxFilter[]): boolean {
        const isMatch = filters.every(filter => this.compare(obj, filter));

        return isMatch;
    }

    compare(data: any, filter: IDxFilter): boolean {
        const { dxPropName, dxSelector, queryName, type, value } = filter;

        if (value) {
            const filterName = dxPropName ? dxPropName : ('' + queryName);
            const dataValue = data[filterName];

            switch (type) {
                case 'text':
                    return this.compareStrings(dataValue, dxSelector, value);
                case 'checkbox':
                    return this.compareBooleans(dataValue, dxSelector, value);
                case 'date':
                case 'dateRange':
                    return this.compareDates(dataValue, dxSelector, value);
                default:
                    throw new Error('DataService: Unsupported filter type!');
            }
        }

        return true;
    }

    setGenericFilter(value: any) {
        return filterModule.setGenericFilter(value);
    }

    getGenericFilter() {
        const { getGenericFilter } = filterModule;
        return getGenericFilter;
    }

    private extractViewName(signalRViewName: string): string {
        const parts = signalRViewName.split(/(?=[A-Z])/);

        const viewName = parts.reduce((sum: string, part: string, idx: number, array: string[]) => {
            if (array.length < 3 || idx < array.length - 2) {
                sum = sum.concat(part);
            }

            return sum;
        }, '');

        return viewName;
    }

    private toQueryString(filters: IDxFilter[], useHiddenFilters?: boolean): string {
        const queryString = filters.filter(f => useHiddenFilters || f.hidden !== true).reduce((query, filter) => {
            query += this.getValue(filter);

            return query;
        }, '?');

        return queryString.substring(0, queryString.length - 1);
    }

    private getValue(filter: IDxFilter): string {
        switch (filter.type) {
            case 'checkbox':
                return this.getCheckBoxValue(filter);
            case 'date':
                return this.getDatePickerValue(filter);
            case 'dateRange':
                return this.getDateRangeValue(filter);
            case 'select':
                return this.getSelectedValue(filter);
            case 'multiSelect':
                return this.getMultiSelectedValue(filter);
            case 'number':
            case 'text':
                return this.getTextBoxValue(filter);
            default:
                return '';
        }
    }

    private getCheckBoxValue(filter: IDxFilter): string {
        if (!filter.queryName) {
            return '';
        }

        return `${filter.queryName}=${filter.value ? 'true' : 'false'}&`;
    }

    private getDatePickerValue(filter: IDxFilter): string {
        return '';
    }

    private getDateRangeValue(filter: IDxFilter): string {
        if (!filter.value || !Array.isArray(filter.value)) {
            return '';
        }

        let queryString = '';
        const useTime = !!filter.params && filter.params.useTime;

        for (let i = 0; i < filter.value.length; i++) {
            const value = filter.value[i];

            if (value) {
                const queryName = filter.queryName[i];
                // todo: add 'YYYY-MM-DD' format to const value and use it here & in DxDateRangeFilter
                const format = useTime ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';
                queryString += `${queryName}=${DateHelper.format(value, format)}&`;
            }
        }

        return queryString;
    }

    private getSelectedValue(filter: IDxFilter): string {
        if (!filter.value || !filter.value.id) {
            return '';
        }

        return !!filter.value.name ? `${filter.queryName}=${encodeURIComponent(filter.value.id)}&` : '';
    }

    private getMultiSelectedValue(filter: IDxFilter): string {
        const { value } = filter;

        if (!value || !value.length) { return ''; }

        if (typeof (value) === 'string') {
            return `${filter.queryName}=${value}&`;
        }

        const query = (value as Array<{ id: string }>).reduce((q, val) => {
            return `${q}${filter.queryName}=${encodeURIComponent(val.id)}&`;
        }, '');

        return query;
    }

    private getTextBoxValue(filter: IDxFilter): string {
        if (!filter.queryName || !filter.value) {
            return '';
        }

        return `${filter.queryName}=${encodeURIComponent(filter.value)}&`;
    }

    private compareDates(value: any, operator: DxSelectorType, expected: any): boolean {
        const d1 = moment(value);
        let d2: moment.Moment;

        switch (operator) {
            case '<':
                d2 = moment(expected);
                return d1.isSameOrBefore(d2, 'date');
            case '>':
                d2 = moment(expected);
                return d1.isSameOrAfter(d2, 'date');
            case '<>':
            case 'contain':
                // value between expected 2 dates
                if (Array.isArray(expected) && expected.length === 2) {
                    const from = expected[0];
                    const to = expected[1];

                    let result = false;
                    if (!from && !to) {
                        result = true;
                    } else if (!to) {
                        result = d1.isSameOrAfter(moment(from), 'date');
                    } else if (!from) {
                        result = d1.isSameOrBefore(moment(to), 'date');
                    } else {
                        result = d1.isBetween(moment(from), moment(to), 'date', '[]');
                    }

                    return result;
                }
                return false;
            case '=':
                return value === expected;
            case '!=':
                return value !== expected;
        }
        return false;
    }

    private compareNumbers(value: any, operator: DxSelectorType, expected: any): any {
        switch (operator) {
            case '<':
                return value < expected;
            case '>':
                return value > expected;
            case '<>':
                if (Array.isArray(expected) && expected.length === 2) {
                    const from = expected[0];
                    const to = expected[1];

                    if (!from && !to) {
                        return true;
                    } else if (!to) {
                        return value >= from;
                    } else if (!from) {
                        return value <= to;
                    } else {
                        return value >= from && value <= to;
                    }
                }
                return false;
            case '=':
                return value === expected;
            case '!=':
                return value !== expected;
            case 'contain':
                return value.toString().includes(expected.toString());
        }
        return false;
    }

    private compareStrings(value: any, operator: DxSelectorType, expected: any): boolean {
        switch (operator) {
            case '=':
                return value === expected;
            case '!=':
                return value !== expected;
            case '<>':
            case 'contain':
                return value.includes(expected);
        }
        return false;
    }

    private compareBooleans(value: any, operator: DxSelectorType, expected: any): boolean {
        switch (operator) {
            case '=':
                return value === expected;
            case '!=':
                return value !== expected;
        }
        return false;
    }

    getdraftNo(): string | null {
        return filterModule.getDraftNo;
    }

    setDraftNo(event: string): void {
        return filterModule.setDraftNo(event);
    }

    getDraft(): IDxFilter[] | null {
        return filterModule.getDraft;
    }

    setDraft(event: IDxFilter[]): void {
        return filterModule.setDraft(event);
    }

    setDraftSearchCriteriaDateFilter(event: IDxFilter[]): void {
        return filterModule.setDraftSearchCriteriaDateFilter(event);
    }

    setDraftSearchCriteriaSupplierIds(event: string[] | null): void {
        return filterModule.setDraftSearchCriteriaSupplierIds(event);
    }

    getDraftSearchCriteriaSupplierIds(): string[] | null {
        return filterModule.getDraftSearchCriteriaSupplierIds;
    }

    private getTextValueEs(filters: IDxFilter[]) {
        const queryString = filters.reduce((query, filter) => {
            return query;
        });

        return queryString?.value;
    }
}

export const filterService = new DxFilterService();

