import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import { $environment } from '@/services/environment.service';
import ListDirective from "./directives/directives";
import { CustomLoaderPlugin } from '@/plugins/custom-loader.plugin';
import { CustomModalPlugin } from '@/plugins/custom-modal.plugin';
import Component from 'vue-class-component'
import { CustomI18NPlugin } from '@/plugins/custom-i18n.plugin';

import 'vue-loaders/dist/vue-loaders.css';
import * as VueLoaders from 'vue-loaders';
import VueWorker from 'vue-worker';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])
Vue.config.productionTip = false;
export const bus = new Vue();
Vue.prototype.$eventBus = bus;
Vue.use(CustomLoaderPlugin);
Vue.use(CustomModalPlugin);
Vue.use(CustomI18NPlugin);
Vue.use(VueLoaders);
Vue.use(VueWorker);
Vue.directive("click-outside", ListDirective);
Vue.prototype.$eventBus = bus;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
