import { IMaterialAttribute } from './material.interface';

export class UserChoice implements IUserChoice {
    details: string;
    exception: IUserChoiceException;
    
    title = 'User Choice';
    description = 'Error has occured';
    version = '';
    timeStamp = new Date();
    userOptions = [];
    id = '';
    deleted = false;
    isInformationOnly = false; // note: user choice is an information only - should not be treated as an error

    type = UserChoiceType.Standard;
    
    constructor(exception: string) {
        this.details = exception;
        this.exception = {
            type: 'Error',
            message: 'Error',
            stackTrace: exception,
            innerError: null
        };
    }
}

export interface IUserChoice {
    title: string;
    description: string;
    details: string;
    version: string;
    exception: IUserChoiceException;
    timeStamp: Date;
    userOptions: IUserChoiceOption[];
    id: string;
    deleted: boolean;
    
    source?: any;
    apiData?: IUserChoiceApiData;
    isInformationOnly: boolean;
    type: UserChoiceType;

    onCancel?: () => void;
}

export interface IUserChoiceException {
    type: string;
    message: string;
    stackTrace: string;
    innerError: any;
}

export interface IUserChoiceOption {
    userChoiceId: string;
    title: string;
    action: string;
    id: string;
    deleted: boolean;

    // todo: Change in the backend PascalCase to camelCase
    Item?: IUserChoiceOptionItem;
    Title?: string;
}

export interface IUserChoiceOptionItem {}
export interface IUserChoiceMaterialItem extends IUserChoiceOptionItem {
    // todo: Change in the backend PascalCase to camelCase
    ItemNumber: string;
    Name: string;
    MaterialAttributes: IMaterialAttribute[];
}

export enum UserChoiceType {
    Standard,
    MaterialItemType = 'MaterialItemType'
}

export enum UserChoiceActionType {
    CLOSE = 'Close',
    RETRY = 'Retry',
    COPY = 'Copy'
}

export interface IUserChoiceApiData {
    url: string;
    headers: any;
    body: any;
}

export function isUserChoice(obj: any): obj is IUserChoice {
    return obj && obj.hasOwnProperty('title') && obj.hasOwnProperty('description') && obj.hasOwnProperty('userOptions');
}

export function isUserChoiceMaterialItem(obj: any): obj is IUserChoiceMaterialItem {
    return obj && obj.hasOwnProperty('ItemNumber');
}
