import { $customViews } from "./custom-views.service";


export default class UrlService {
    private state = {
        hash: '',
        host: '',
        hostname: '',
        href: '',
        origin: '',
        pathname: '',
        port: '',
        protocol: '',
        searchParams: [] as Array<{ key: string, value: string}>,
        customViewName: ''
    };
    
    constructor(location: Location) {
        this.state.hash = location.hash;
        this.state.host = location.host;
        this.state.hostname = location.hostname;
        this.state.href = location.href;
        this.state.origin = location.origin;
        this.state.pathname = location.pathname;
        this.state.port = location.port;
        this.state.protocol = location.protocol;

        this.state.customViewName = this.extractCustomViewName(this.state.hash);
        
        this.state.searchParams = this.extractSearchParams(this.state.href);
    }
    
    get searchParams() {
        return this.state.searchParams;
    }

    get customViewName() {
        return this.state.customViewName;
    }
    
    update(searchQuery: string) {
        const idx = this.state.hash.indexOf('?');
        if (idx > -1) {
            this.state.hash = this.state.hash.substring(0, idx);
        }
        
        this.state.hash += searchQuery;
        
        history.pushState(null, '', this.state.hash);
    }

    private extractCustomViewName(rawUrl: string): string {
        const idx = rawUrl.indexOf('custom-view');

        if (idx > -1) {
            return rawUrl.substring(idx + 12);
        }
        return $customViews.CustomViewName;
    }
    
    private extractSearchParams(rawUrl: string): Array<{ key: string, value: string }> {
        let params: Array<{ key: string, value: string }> = [];
    
        if (!rawUrl.includes('?')) {
            return params;
        }
    
        const queryString = rawUrl.substring(rawUrl.indexOf('?') + 1);
        const decodedQueryString = decodeURIComponent(queryString);
    
        params = decodedQueryString.split('&').map(param => {
            const [key, value] = param.split('=');
            return {
                key: key.trim(),
                value: this.parse(value ? value.trim() : '')
            };
        });
    
        return params;
    }
    
    
    private parse(str: string) {
        const testBool = str.toLocaleUpperCase();       
        if (testBool === 'TRUE' || testBool === 'FALSE') {
            return testBool === 'TRUE';
        }
        
        const testNumber = parseFloat(str);
        if (!isNaN(testNumber) && testNumber.toString().length === str.length) {
            return testNumber;
        }
        
        if (str.startsWith('{') && str.endsWith('}')) {
            return JSON.parse(str);
        }
        
        return str;
    }
}
