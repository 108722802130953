import { IEnvironment } from '@/interfaces/environment.interface';
import Axios from 'axios';
import { isJSON } from '@/utilities/is-json.function';

// EnvironmentKeys must correspond with IEnvironment interface
type EnvironmentKey = 'apiUrl' | 'hostUrl' | 'signalR' | 'name' | 'routes';
// Service only to be used for VUE & user environment variables
class EnvironmentService {
    isReady: boolean = false;
    private envPromise: any;
    private env: IEnvironment = {
        version_main: 2,
        version_release: 83, // ask Soren about it ;)
        version_build: 55 // note: increment after adding a new feature or fixing a bug
    };

    constructor() {
        this.init();
    }

    async whenReady(): Promise<void> {
        if (this.isReady) { return; }

        return this.envPromise;
    }

    get apiURL() {
        return this.getFromJson('apiUrl') || this.VUE_APP_ApiUrl;
    }

    // required for work with local background
    // get hostURL() {
    //     const url = process.env.VUE_APP_HostUrl || '';

    //     return url;
    // }

    get signalRConfig() {
        const config = this.getFromJson('signalR') || this.VUE_APP_SignalR;

        if (!config) {
            return null;
        }

        return JSON.parse(config);
    }

    get name() {
        return this.getFromJson('name') || this.VUE_APP_EnvName;
    }

    get defaultDateTime(): string | undefined {
        return process.env?.VUE_APP_Default_date_time;
    }

    get defaultNumberFormat(): string | undefined {
        return process.env?.VUE_APP_Default_number_format;
    }

    get routes(): any {
        return this.getFromJson('routes') || this.VUE_APP_Routes;
    }

    get version(): string {
        return `${this.env.version_main}.${this.env.version_release}`;
    }

    get versionExtended(): string {
        return `${this.version}.${this.env.version_build}`;
    }

    private getFromJson(key: EnvironmentKey): any {
        return this.env[key] || null;
    }

    private async init() {
        this.envPromise = new Promise<void>(async (res, rej) => {
            try {
                const defaultConfig = {};

                const webJson = await this.getWebJson();

                this.tryMergeEnvJsons(defaultConfig, webJson);
            } catch (error) {
                return rej();
            }

            this.isReady = true;
            return res();
        });

        return this.envPromise;
    }

    private async getWebJson() {
        try {
            const jsonPath = `${window.location.origin}/env.json`;

            const webJson = await Axios.get(jsonPath, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            return webJson.data;
        } catch (error) {
            return {};
        }
    }

    private tryMergeEnvJsons(json: any, webJson: any) {
        if (json && webJson) {
            this.env = Object.assign({}, this.env, json, webJson);
        }
    }

    private get VUE_APP_ApiUrl() {
        return process.env.VUE_APP_ApiUrl as string || '';
    }

    private get VUE_APP_SignalR() {
        return process.env.VUE_APP_SignalR as string || '';
    }

    private get VUE_APP_EnvName() {
        return process.env.NODE_ENV as string || '';
    }

    private get VUE_APP_Routes() {
        const str = process.env.VUE_APP_Routes as string;

        if (!isJSON(str)) {
            return [];
        }
        const json = JSON.parse(str);

        return json;
    }
}

export const $environment = new EnvironmentService();
